<template>
    <header>
        <div class="header-content">
            <div class="header-left">
                <div class="header-logo" @click="goToHome(lessonData[currentTask],this.$route.meta.type)">
                    <img class="header-logo-img" src="@/assets/images/logo-white.svg" />
                </div>
                <div class="header-line"></div>
                <div class="current-dashboard" v-if="this.$route.meta.type==='tasklist'">StudyLog System</div>
                <div class="current-task-list" v-else>
                  <div v-for="(lesson, index) in lessonData" :key="'item'+index" ref="taskItems">
                    <div :id="'task-item-'+index" class="header-task-item" 
                    :class="
                    index === Number(currentTask)? 'active '+lessonUnitsStatus[index]:' '+lessonUnitsStatus[index]
                    "  
                    @click="gotoPage(lessonData[index],lessonUnitsStatus[index],index, lessonData[currentTask])"
                  >
                        <div class="header-task-num" v-if="lessonUnitsStatus[index] !=='completed'">{{ index+1 }}</div>
                        <div class="header-task-num" v-if="lessonUnitsStatus[index] ==='completed'">
                          <img class="icon-completed" src="@/assets/images/msg-complete.png" />
                        </div>
                        <div class="header-task-desc">
                            <div class="header-task-type">{{lesson.type}}</div>
                            <div class="header-task-percentage" v-if="(lesson.type === 'video' && lesson.startTime !==null)">
                              <!--<template v-if="lessonUnitsStatus[index] ==='completed'">100%</template>-->
                              {{lesson.videoCompletePercentage}}%
                            </div>
                            <div class="header-task-percentage" v-if="(lesson.type !== 'video' && lesson.startTime !==null)">
                              {{Math.floor((lesson.checkedQuestionIndexNos.length / lesson.instructQuestionIndexNos.length)*100)}}%
                            </div>
                        </div>
                        <img v-if="index !== lessonData.length-1" class="icon-arrow" src="@/assets/images/icon-arrow.svg" />
                    </div>
                  </div>
                </div>
            </div>
            <div class="header-right">
                <div class="header-info-item">
                    <div class="header-info-left">
                        <img class="header-info-img" src="@/assets/images/icon-timer.svg" />
                    </div>
                    <div class="header-info-right">
                        <div class="header-info-title">Time Left</div>
                        <div class="header-info-text">{{ countdownTime }}</div>
                    </div>
                </div>
                <div class="header-line"></div>
                <div class="header-info-item">
                    <div class="header-info-left">
                        <div class="header-info-circle">{{ String(student).charAt(0) }}</div>
                    </div>
                    <div class="header-info-right">
                        <div class="header-info-title">{{school}}</div>
                        <div class="header-info-text">{{ student }}</div>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import { useStore } from 'vuex';
import { computed, watchEffect, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router'

export default {
  name: 'Header',
  setup(){
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const currentTask = computed(() =>route.params.id)
    const lessonData = computed(() =>store.state.lessonUnits)
    const lessonUnitsStatus = computed(() =>store.state.lessonUnitsStatus)

    const taskItems = ref([]);
    const timerInterval = ref();
    const countdownTime = ref('0hr0m');

    // Calculate countdown time based on current time and end time
    const calculateCountdownTime = () => {
      const endTime = store.state.currentLesson?.endTime;

      if (endTime) {
        const currentTime = new Date().getTime();
        const end = new Date(endTime).getTime();
        const remainingMilliseconds = Math.max(end - currentTime, 0);
 
        if (remainingMilliseconds <= 0) {
          clearInterval(timerInterval.value);
          countdownTime.value = '0hr0m';
        } else {
          const hours = Math.floor(remainingMilliseconds / (1000 * 60 * 60));
          const minutes = Math.floor((remainingMilliseconds % (1000 * 60 * 60)) / (1000 * 60));

          countdownTime.value = `${hours}hr ${minutes}m`;
        }
      } else {
        countdownTime.value = '0hr0m';
      }
    };

    const goToHome = ((lesson, currentTask)=>{
      if(lesson?.type !=='test' && currentTask !=='tasklist'){
        router.push({path:'/dashboard'})
      }
    })

    const gotoPage = ((lesson,status,number, currentLesson)=>{
      //console.log(lesson)
      //console.log(status)
      //console.log(number)
      if(currentLesson?.type !=='test'){
        if(status !== 'notReady'){
          if(lesson?.type !=='test'){
            if (lesson?.type === 'video') {
              router.push(`/dashboard/video/${number}`)
            } else if (lesson?.type === 'exercise') {
              router.push(`/dashboard/exercise/${number}`)
            } 
          }else if(status === 'start'){
            router.push(`/dashboard/test/${number}`)
          }
        }
      }
    })
    
    // Update countdown time every second
    watchEffect(() => {
      taskItems.value = Array.from(document.querySelectorAll('.header-task-item'));

      const taskIndex = Number(currentTask.value);
      const taskItem = taskItems.value[taskIndex];
      if (taskItem) {
        taskItem.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
      calculateCountdownTime();

      // Update countdown time every second
      timerInterval.value = setInterval(() => {
        //calculateCountdownTime();
      }, 1000);

      // Clear interval when component is unmounted
      return () => {
        clearInterval(timerInterval.value);
      };
    });

    return{
      student: computed(() => store.state.student?.name),
      school: computed(() => store.state.student?.school?.name),
      lessonData,
      lessonUnitsStatus,
      currentTask,
      countdownTime,
      taskItems,
      goToHome,
      gotoPage
    }
  }
}
</script>

<style>

header{
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 65px;
  background-color: #222222;
  border-top: 3px solid #CDA448;
  z-index: 1000;
}

.header-content{
  position: relative;
  display: flex;
  flex-direction: row;
  width: 960px;
  justify-content: center;
  padding: 15px 10px;
}

.header-left{
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.header-logo{
  position: relative;
  display: flex;
  justify-content: center;
  width: 120px;
}

.header-logo-img{
  position: relative;
  width: 100%;
}

.header-line{
  position: relative;
  display: block;
  width: 1px;
  height: 24px;
  margin: 0px 10px;
  background-color:#FFFFFF4D;
}

.current-dashboard{
  font-family: 'Comfortaa';
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  text-align: left;
  width: 50px;
  color: #FFF;
}

.current-task-list{
  position: relative;
  display: flex;
  align-items: center;
  max-width: 600px;
  overflow-x: auto;
  overflow-y: hidden;
}


.header-task-item{
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 10px;
  cursor: pointer;
}

.icon-completed{
  width: 22px;
}

.icon-arrow{
  margin-left: 15px;
  margin-right: 10px;
}

.active .header-task-num{
  color:#ffffff98;
  background-color: #cda54890;
}

.header-task-num{
  position: relative;
  font-family: 'Montserrat';
  font-size: 8px;
  font-weight: 700;
  line-height: 11.2px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: #ffffff66;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  margin-right: 10px;
}

.header-task-num::before{
  content: ' ';
  position: absolute;
  width: 22px;
  height: 22px;
  border-radius: 100%;
  border: 1px solid #ffffff66;
}

.continue .header-task-type,
.completed .header-task-type,
.active .header-task-type{
  color: #CDA448;
}

.header-task-type{
  position: relative;
  font-family: 'Montserrat';
  font-size: 10px;
  font-weight: 700;
  line-height: 14px;
  color: #ffffff66;
  text-transform: uppercase;
  margin-bottom: 2px;
}

.header-task-percentage{
  position: relative;
  font-family: 'Comfortaa';
  font-size: 8px;
  font-weight: 700;
  line-height: 8.92px;
  color: #ffffff66;
}

.continue .header-task-percentage,
.completed .header-task-percentage{
  color: #CDA448;
}

.header-right{
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: auto;
}

.header-info-item{
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.header-info-img{

}

.header-info-right{
    position: relative;
    margin-left: 5px;
}

.header-info-circle{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    font-family: 'Montserrat';
    font-size: 12px;
    font-weight: 500;
    line-height: 14.63px;
    text-align: center;
    background: #CDA448;
    border-radius: 100%;
}

.header-info-title{
    position: relative;
    font-family: 'Comfortaa';
    font-size: 8px;
    font-weight: 500;
    line-height: 8.92px;
    color: #E8D17E;
}

.header-info-text{
    position: relative;
    font-family: 'Comfortaa';
    font-size: 10px;
    font-weight: 500;
    line-height: 14px;
    text-align: left;
    color: #FFFFFF;

}

 /* width */
 ::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
/* Track */
 ::-webkit-scrollbar-track {
  background: #f1f1f1;
}
/* Handle */
::-webkit-scrollbar-thumb {
   background: #bec4c4;
   border-radius: 20px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

</style>