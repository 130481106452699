<template>
    <div id="pageTest" class="content-area" :class="isLessThan15Min?'warning':''" >
        <div class="page-container">
            <transition>
                <div class="page-content" v-show="loaded">
                    <div class="page-type">Test</div>
                    <div class="page-title">{{ taskData.name }}</div>
                    <div class="page-content-area">
                    <div class="page-desc"><i class="bi bi-exclamation-circle desc-icon-exclamation"></i>Instruction: {{ taskData.instruction.replace("###name###", taskData.name) }}</div>
                    <div class="page-text-exe-area">
                            <div class="page-timer-area">
                                <div class="timer-desc">Try to complete within {{ Math.floor(timeLimit/60) }}min</div>
                                <div class="timer-content">
                                    <div class="timer-title">Time Left</div>
                                    <div class="timer-text">{{ remainingTime }}</div>
                                    <div class="timer-progress">
                                        <circular-progress-bar :progress="timeProgress" size="250px" :ring-thickness="8" trackColor="#F2F2F2" :progressColor="progressColor" />
                                    </div>
                                </div>
                                <div class="page-completed-info">
                                    <div class="page-completed-title">completed</div>
                                    <div class="page-completed-count"><span>{{ checkedQuestions.length }}</span>/{{ taskData.instructQuestionIndexNos.length }}</div>
                                </div>
                            </div>
                            <div class="page-ans-area">
                                <div class="ans-desc">Tap the question number that you have completed. </div>
                                <div class="ans-content">
                                    <div class="ans-item" v-for="questionId in taskData.instructQuestionIndexNos" v-bind:key="'ans-item-'+questionId" 
                                    v-bind:class="checkedQuestions.includes(String(questionId))? 'checked':''" @click="toggleQuestion(String(questionId))">
                                        <i class="bi " v-bind:class="checkedQuestions.includes(String(questionId))? 'bi-check-circle':'bi-circle'"></i>{{ questionId+1 }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="page-content-bottom">
                    <div class="page-button-right">
                        <div class="page-button" @click="markAllDone">Mark all as done</div>
                        <div class="page-button black-style space-left" @click="checkSubmit" :class="isAllChecked()?'':'notActive'"><i class="bi bi-check-circle"></i>Submit Test</div>
                    </div>
                    </div>
                </div>
            </transition>
        </div>
        <StartTestModal :time="Math.floor(taskData.timeLimit/60)" @startTask="startTask" @backHome="goToHome"/>
        <CompleteTestModal @completeTask="completeTaskModal" @closeModal="closeCompleteTestModal"/>
        <TimeIsUpModal @completeTask="completeTaskModal"/>
    </div>
</template>
  
<script>
import CircularProgressBar from '../../components/CircularProgressBar.vue';

import StartTestModal from '@/components/modal/StartTest.vue';
import TimeIsUpModal from '@/components/modal/TimeIsUp.vue';
import CompleteTestModal from '@/components/modal/CompleteTest.vue';

import { useStore } from 'vuex'; 
import { computed, nextTick, onMounted, onUnmounted, ref, getCurrentInstance } from 'vue';
import { useRoute, useRouter } from 'vue-router'
import { useQuery, useMutation } from '@vue/apollo-composable'

import StartLessonUnitMutation from '../../components/gql/StartLessonUnit.gql'
import SubmitTestLessonunit from '../../components/gql/SubmitTestLessonunit.gql'
import CompleteLessonunitQuestionMutation from '../../components/gql/CompleteLessonunitQuestion.gql'
import CompleteAllLessonunitQuestionsMutation from '../../components/gql/CompleteAllLessonunitQuestions.gql'

export default {
    components: {
        CircularProgressBar,
        StartTestModal,
        TimeIsUpModal,
        CompleteTestModal,
    },
    setup(){
        const route = useRoute()
        const router = useRouter()
        const store = useStore()

        const instance = getCurrentInstance();

        // Define the resetTime method
        const resetTime = () => {
            if (instance) {
                instance.emit('resetTime');
            }
        };


        const remainingTime = ref('0:00:00');
        const isLessThan15Min = ref(false);

        let progressColor = ref('#CDA448')

        let timerInterval;
        let startTestModal;
        let completeTestModal;
        let timeupModal;

        const taskId = route.params.id;
        const taskData = computed(() =>store.state.lessonUnits[taskId]);
        const isPageStart = computed(() =>store.state.currentLesson.studentStartTime);
        
        let checkedQuestions = ref([]);
        for(let q in taskData.value.checkedQuestionIndexNos){
            let temp = taskData.value.checkedQuestionIndexNos[q];
            checkedQuestions.value.push(String(temp));
        }

        let startTime = ref(taskData.value.startTime);
        if(startTime.value===null){
            startTime.value = new Date();
        }
        let timeLimit = ref(taskData.value.timeLimit); 
        let timeProgress = ref(0);

        let loaded = ref(false)

        const { mutate:startLessonUnit, onDone:onStartLessonUnitDone  } = useMutation(StartLessonUnitMutation, 
            () => ({      
                variables:{
                    startLessonunitId: String(taskData.value.id)
                }
            })
        )

        const { mutate:completeLessonunitQuestion} = useMutation(CompleteLessonunitQuestionMutation)

        const { mutate:completeAllLessonunitQuestions } = useMutation(CompleteAllLessonunitQuestionsMutation, 
            () => ({      
                variables:{
                    completeAllLessonunitQuestionsId: String(taskData.value.id),
                }
            })
        )

        const { mutate: competeTest, onDone: onCompeteTestDone } = useMutation(SubmitTestLessonunit, 
            () => ({      
                variables:{
                    submitTestLessonunitId: String(taskData.value.id)
                }
            })
        )

        onStartLessonUnitDone((res) => {
            if(res.data.startLessonunit.success){
                store.commit('setLoading', false);
                store.commit('updateData', {data:res.data.startLessonunit.lessonunit, indexId:taskId});
                startTimer();
            }else{
                openStartTestModal();
            }
        })

        onCompeteTestDone((res) => {
            //console.log(res);
            store.commit('setLoading', false);
            if(res.data.submitTestLessonunit.success){
                store.commit('updateData', {data:res.data.submitTestLessonunit.lessonunit, indexId:taskId});
                goToHome();
            }
        })

        const toggleQuestion = (questionId) => {
            //console.log(questionId);
            resetTime();
            let isComplete;
            if (checkedQuestions.value.includes(questionId)) {
                checkedQuestions.value = checkedQuestions.value.filter((id) => String(id) !== questionId);
                isComplete = false;
            } else {
                checkedQuestions.value.push(questionId);
                isComplete = true;
            }
            completeLessonunitQuestion({
                completeLessonunitQuestionId: String(taskData.value.id),
                questionIndexNo: Number(questionId),
                isComplete: isComplete
            })
            store.commit('updateCheckedQuestions',{indexId:taskId, checkedQuestionIndexNos:checkedQuestions.value})
        };

        function startTask(){
            resetTime();
            store.commit('setLoading', true);
            closeStartTestModal();
            startLessonUnit();
        }

        const startTimer = () => {
            loaded.value = true;

            const start = new Date(startTime.value);
            const endTime = new Date(start.getTime() + timeLimit.value * 1000);

            timerInterval = setInterval(() => {
                const currentTime = new Date();
                const remainingMilliseconds = Math.max(endTime - currentTime, 0);

                if (remainingMilliseconds === 0) {
                    stopCountdown();
                    isTimeUp();
                }

                const progress = ((timeLimit.value * 1000 - remainingMilliseconds) / (timeLimit.value * 1000)) * 100;
                timeProgress.value = Math.round(progress);

                const hours = Math.floor(remainingMilliseconds / (1000 * 60 * 60));
                const minutes = Math.floor((remainingMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
                const seconds = Math.floor((remainingMilliseconds % (1000 * 60)) / 1000);

                remainingTime.value = `${hours}:${minutes}:${seconds}`;
                if(remainingMilliseconds < 900000){
                    isLessThan15Min.value = true
                    progressColor.value = '#D40000'
                }
            }, 1000);
        };

        const stopCountdown = () => {
            clearInterval(timerInterval);
        };

        const isTimeUp = () => {
            openTimeUpModal();
        };


        const openStartTestModal = (()=>{
            resetTime();
            if(startTestModal){
                startTestModal.show();
            }
        })

        
        const openTimeUpModal = (()=>{
            resetTime();
            if(timeupModal){
                timeupModal.show();
            }
        })

        const openCompleteTestModal = (()=>{
            resetTime();
            if(completeTestModal){
                completeTestModal.show();
            }
        })

        const closeStartTestModal = (()=>{
            resetTime();
            if(startTestModal){
                startTestModal.hide();
            }
        })

        const closeTimeUpModal = (()=>{
            resetTime();
            if(timeupModal){
                timeupModal.hide();
            }
        })

        const closeCompleteTestModal = (()=>{
            resetTime();
            if(completeTestModal){
                completeTestModal.hide();
            }
        })

        const goToHome = (()=>{
            router.push({path:'/dashboard'})
        })

        const completeTaskModal = (()=>{
            resetTime();
            closeCompleteTestModal();
            store.commit('setLoading', true);
            competeTest();
        })

        const markAllDone = (()=>{
            resetTime();
            if(checkedQuestions.value.length !== taskData.value.instructQuestionIndexNos.length){
                checkedQuestions.value = [];
                for(let q in taskData.value.instructQuestionIndexNos){
                    const value = taskData.value.instructQuestionIndexNos[q];
                    checkedQuestions.value.push(String(value));
                }
                completeAllLessonunitQuestions();
                store.commit('updateCheckedQuestions',{indexId:taskId, checkedQuestionIndexNos:checkedQuestions.value})
            }
        })

        const checkSubmit = (()=>{
            resetTime();
            if(isAllChecked()){
                openCompleteTestModal();
            }
        })

        const isAllChecked = (()=>{
            if(checkedQuestions.value.length !== taskData.value.instructQuestionIndexNos.length){
                return false
            }else{
                return true
            }
        })


        onMounted(async()=>{
            await nextTick();
            resetTime();
            startTestModal = new bootstrap.Modal(document.getElementById('startTest')) 
            timeupModal = new bootstrap.Modal(document.getElementById('timeIsUp')) 
            completeTestModal = new bootstrap.Modal(document.getElementById('completeTest')) 
            if(isPageStart.value !== null){
                if(taskData.value.startTime === null){
                    openStartTestModal();
                }else{
                    startTimer();
                }
            }
        })

        onUnmounted(()=>{
            closeStartTestModal();
            closeTimeUpModal();
            closeCompleteTestModal();
        })

        return{
            loaded,
            progressColor,
            taskData,
            checkedQuestions,
            timeLimit,
            remainingTime,
            timeProgress,
            isLessThan15Min,
            isAllChecked,
            toggleQuestion,
            goToHome,
            startTask,
            markAllDone,
            checkSubmit,
            completeTaskModal,
            closeCompleteTestModal,
        }

    },
}
</script>

<style scoped>

#pageTest.warning{
    background: #F2E9E9;
}

.warning .page-type{
    color: #7D2929;
}

.warning .page-desc{
    border: 1px solid #7D2929;
    background-color: #d4000044;
}

.warning .page-completed-title{
    color: #7D2929;
}

.warning .page-completed-count span
{
    color: #D40000;
}

.warning .page-button{
    color: #7D2929;
    border: 2px solid #7D2929;
}

.warning .page-button.black-style{
    color: #FFF;
}

#pageTest{
    background: #F9F9F9;
}

.page-text-exe-area{
    position: relative;
    display: flex;
    flex-direction: row;
    background: #FFF;
}

.page-timer-area{
    position: relative;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    text-align: center;
    width: 42%;
    margin: 10px 0px;
    border-right: 1px solid #CDCDCD;
}

.ans-desc,
.timer-desc{
    position: relative;
    font-family: 'Comfortaa';
    font-size: 12px;
    font-weight: 400;
    line-height: 13.38px;
    color: #000;
    margin-top: 20px;
    margin-bottom: 20px;
}

.timer-content{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 250px;
    height: 250px;
    margin: 0 auto;
}

.timer-progress{
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 400;
}

.timer-title{
    position: relative;
    font-family: 'Montserrat';
    font-size: 16px;
    font-weight: 700;
    line-height: 19.5px;
    text-align: center;
    color: #A58245;
    text-transform: uppercase;
    z-index: 500;
}

.timer-text{
    position: relative;
    font-family: 'Montserrat';
    font-size: 40px;
    font-weight: 700;
    line-height: 48.76px;
    letter-spacing: 2px;
    text-align: center;
    color: #000;
    margin-bottom: 10px;
    z-index: 500;
}

.timer-pause{
    margin: 0 auto;
    padding: 10px 15px;
    width: fit-content;
    z-index: 500;
}

.timer-pause .a{
    position: relative;
    text-align: left;
    font-size: 12px;
    margin-left: 0px;
}

.timer-pause .b{
    font-size: 16px;
}

.page-completed-info{
    position: relative;
    margin-top: 20px;
    margin-bottom: 20px;
}

.page-completed-title{
    position: relative;
    font-family: 'Montserrat';
    font-size: 12px;
    font-weight: 700;
    line-height: 14.63px;
    text-align: center;
    color: #A58245;
    text-transform: uppercase;
    margin-bottom: 5px;
}

.page-completed-count{
    position: relative;
    font-family: 'Montserrat';
    font-size: 24px;
    font-weight: 600;
    line-height: 29.26px;
    text-align: center;
    color: #AAAAAA;
}

.page-completed-count span{
    font-family: 'Montserrat';
    font-size: 40px;
    font-weight: 700;
    line-height: 48.76px;
    color: #A58245;
}

.page-ans-area{
    position: relative;
    width: 58%;
    padding-left: 20px;
}

.ans-content{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    max-height: 365px;
    overflow-y: scroll;
}

.ans-item{
    position: relative;
    display: flex;
    align-content: center;
    justify-content: space-around;
    text-align: center;
    width: 18%;
    padding: 10px 5px;
    margin-bottom: 10px;
    border-radius: 100px;
    border: 1px solid #989898;
    cursor: pointer;
    margin-right: 5px;
}

.ans-item.checked{
    background-color: #000;
    color: #FFF;
}

.ans-item.checked i{
    color: #CDA448;
}

.ans-item i{
    color: #989898;
    width: 24px;
    height: 24px;
}
</style>
  