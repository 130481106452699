<template>
  <RouterView/>
  <LoadingScreen v-if="isLoading"/>
  <VersionFooter />
</template>

<script>
import { RouterView } from 'vue-router'
import LoadingScreen from '@/components/LoadingScreen.vue';
import { computed } from 'vue';
import { useStore } from 'vuex'; 
import VersionFooter from './components/VersionFooter.vue';

export default {
  name: "App",
  components:{
    RouterView,
    LoadingScreen,
    VersionFooter
  },
  setup() {
    const store = useStore();
    const isLoading = computed(() => store.state.isLoading);
    return {
      isLoading,
    };
  },
};

</script>
<style>

</style>
