<template>
    <div id="pauseExercise" class="modal modal-msg-container fade" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="modal-msg-content">
              <div class="modal-msg-title">Continue the Exercise?</div>
              <div class="modal-msg-desc">
                <p>You will be redirected to the exercise automatically after {{ pauseTime/60 }} mins.</p>
              </div>
              <div class="timer-content">
                  <div class="timer-title">Time Left</div>
                  <div class="timer-text">
                    {{ formatTime(countdownTime).minutes }}:{{ formatTime(countdownTime).seconds }}
                  </div>
                  <div class="timer-progress">
                      <circular-progress-bar :progress="progressValue()" size="250px" :ring-thickness="8" trackColor="#F2F2F2" progressColor="#CDA448" />
                  </div>
              </div>
              <div class="page-button black-style" @click="clickContinue" ><i class="bi bi-check-circle"></i>continue</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import CircularProgressBar from '@/components/CircularProgressBar.vue';

  export default {
    name: 'ModalComponent',
    components:{
      CircularProgressBar
    },  
    props: {
      pauseTime: {
        type: Number,
        default: 300,
      }
    },
    data() {
      return {
        countdownTime: this.pauseTime,
        countdownInterval: null,
      };
    },
    methods: {
      clickContinue(){
        this.stopCountdown();
        this.$emit('closeModal')
      },
      formatTime(timeInSeconds) {
        const minutes = Math.floor(timeInSeconds / 60);
        const seconds = timeInSeconds % 60;

        return {
          minutes: String(minutes).padStart(2, "0"),
          seconds: String(seconds).padStart(2, "0")
        };
      },
      progressValue(){
        return ((this.pauseTime - this.countdownTime) / this.pauseTime) * 100;
      },
      startCountdown() {
        this.countdownTime = this.pauseTime;
        this.countdownInterval = setInterval(() => {
          this.countdownTime--;
          if (this.countdownTime <= 0) {
            this.clickContinue();
          }
        }, 1000);
      },
      stopCountdown() {
        clearInterval(this.countdownInterval);
      }
    }
  }
  </script>

<style scoped>

.timer-desc{
    position: relative;
    font-family: 'Comfortaa';
    font-size: 12px;
    font-weight: 400;
    line-height: 13.38px;
    color: #000;
    margin-top: 20px;
    margin-bottom: 20px;
}

.timer-content{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 250px;
    height: 250px;
    margin-bottom: 20px;
}

.timer-progress{
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 400;
}

.timer-title{
    position: relative;
    font-family: 'Montserrat';
    font-size: 16px;
    font-weight: 700;
    line-height: 19.5px;
    text-align: center;
    color: #A58245;
    text-transform: uppercase;
    z-index: 500;
}

.timer-text{
    position: relative;
    font-family: 'Montserrat';
    font-size: 40px;
    font-weight: 700;
    line-height: 48.76px;
    letter-spacing: 2px;
    text-align: center;
    color: #000;
    margin-bottom: 10px;
    z-index: 500;
}

.timer-pause{
    margin: 0 auto;
    padding: 10px 15px;
    width: fit-content;
    z-index: 500;
}

.timer-pause .a{
    position: relative;
    text-align: left;
    font-size: 12px;
    margin-left: 0px;
}

.timer-pause .b{
    font-size: 16px;
}
</style>