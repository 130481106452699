import { createApp, provide, h } from 'vue'
import { ApolloClient, InMemoryCache, gql } from '@apollo/client';
import { DefaultApolloClient } from '@vue/apollo-composable'
import apolloClient from "./graphqlConfig";
import { initializeApp } from "firebase/app";
import config from './config';


import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './assets/main.css'

import App from './App.vue'
import router from './router/index'
import store from './store/store'


initializeApp(config.firebase);

const app = createApp(App)

app.provide(DefaultApolloClient, apolloClient);

app.use(store)
app.use(router)
app.mount('#app')
