<template>
    <div id="handInHW" class="modal modal-msg-container long-msg fade" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="modal-msg-content">
              <div class="modal-msg-icon"><img src="@/assets/images/msg-homework.png"></div>
              <div class="modal-msg-title">hand in assignments before Lesson</div>
              <div class="modal-msg-desc">Please submit the assignments to the admin and activate lesson today:</div>
              <ul class="assignments-list">
                <li v-for="(msg,i) in msgData" :key="'msg'+i">
                  <p class="assignments-list-name">{{ msg.name }}</p>
                  <p class="assignments-list-instruction">( {{ msg.instruction }} )</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ModalComponent',
    props: {
      msgData:{
        type: Object
      }
    }
  }
  </script>