import { ApolloClient, createHttpLink, InMemoryCache, DefaultOptions } from "@apollo/client/core";
import { setContext } from "@apollo/client/link/context";
import { ApolloLink } from "@apollo/client";
import { getAuth, onAuthStateChanged, onIdTokenChanged } from "firebase/auth";
import config from './config';

const httpLink = createHttpLink({
  uri: config.server.graphql_uri, 
});

const authLink = setContext(async (_, { headers }) => {
  const auth = getAuth();
  const user = await new Promise((resolve) => {
    onAuthStateChanged(auth, (user) => {
      resolve(user);
    });
  });
  //console.log(user);
  let token;
  if (user) {
    token = await user.getIdToken();
    //console.log(token); 
  }

  return {
    headers: {
      ...headers,
      authorization: token ? `${token}` : "",
    },
  };
});

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
}

const apolloClient = new ApolloClient({
  link: ApolloLink.from([authLink, httpLink]),
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions,
});

export default apolloClient;