import { createRouter, createWebHistory } from 'vue-router'
import { getAuth, onAuthStateChanged } from "firebase/auth"

import Landing from '../pages/landing/Landing.vue'
import Dashboard from '../pages/dashboard/Dashboard.vue'
import TaskList from '../pages/tasklist/TaskList.vue'
import Video from '../pages/video/Video.vue'
import Exercise from '../pages/exercise/Exercise.vue'
import Test from '../pages/test/Test.vue'
import Login from '../pages/login/Login.vue'
import Logout from '../pages/logout/Logout.vue'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/login',
      name: 'login',
      component: Login,
    },
    {
      path: '/logout',
      name: 'logout',
      component: Logout,
      meta: { requiresAuth: true }
    },
    {
      path: '/',
      name: 'landing',
      component: Landing,
      meta: { requiresAuth: true }
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: Dashboard,
      meta: { requiresAuth: true },
      children: [
        {
          path: '',
          name: 'tasklist',
          component: TaskList,
          meta:{
            type:'tasklist'
          }
        },
        {
          path: 'video/:id',
          name: 'video',
          component: Video,
          meta:{
            type:'video'
          }
        },
        {
          path: 'exercise/:id',
          name: 'exercise',
          component: Exercise,
          meta:{
            type:'exercise'
          }
        },
        {
          path: 'test/:id',
          name: 'test',
          component: Test,
          meta:{
            type:'test'
          }
        }
      ]
    }
  ]
})


router.beforeEach(async (to, from, next) => {
  const auth = getAuth();
  try {
    const user = await new Promise((resolve) => {
      onAuthStateChanged(auth, (user) => {
        resolve(user);
      });
    });

    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

    if (requiresAuth && !user) {
      next('/login');
    } else {
      next();
    }
  } catch (error) {
    console.error('Error checking authentication state:', error);
    next('/error');
  }
});

export default router
