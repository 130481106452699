<template>
  <Headers v-if="loaded"/>
  <RouterView ref="routeViewModalRef" :key="route.fullPath" v-if="loaded" @resetTime="resetIdleTime"/>
  <HandInModal :msgData="msgData" @closeModal="closeHandIn"/>
  <CongratulationsModal :nextDate="nextDate" :msgData="msgData2"  @closeModal="closeCongratulations" />
  <IdleMsgModal @closeModal="closeIdleMsg"/>
  <formMsgModal ref="formMsgModalRef" :formShowTime="formShowTime" :formLink="formLink" @closeModal="closeFormMsg"/>
  <LessonEndsModal :nextDate="nextDate" :msgData="msgData2"/>
  <UpdateMsgModal />
</template> 

<script>
import Headers from '@/components/Header.vue'

import Video from '@/pages/video/Video.vue'

import HandInModal from '@/components/modal/HandIn.vue';
import formMsgModal from '@/components/modal/formMsg.vue';
import IdleMsgModal from '@/components/modal/idleMsg.vue';
import LessonEndsModal from '@/components/modal/LessonEnds.vue';
import CongratulationsModal from '@/components/modal/Congratulations.vue';
import UpdateMsgModal from '@/components/modal/UpdateMsg.vue';

import { useStore } from 'vuex';
import { computed, onBeforeUnmount, onMounted, onUnmounted, watch, ref, nextTick } from 'vue';
import { useQuery } from '@vue/apollo-composable'

import CheckLessonDataQuery from '../../components/gql/CheckLessonData.gql'
import GetLessonDataQuery from '../../components/gql/GetLessonData.gql'
import { useRoute , useRouter } from 'vue-router';

import { eventBus }  from '@/components/eventBus';

export default {
  name: 'Dashboard',
  components: {
    Headers,
    Video,
    HandInModal,
    IdleMsgModal,
    formMsgModal,
    LessonEndsModal,
    CongratulationsModal,
    UpdateMsgModal
  },
  setup() {
    const store = useStore();
    const route = useRoute()
    const router = useRouter()
    
    const { result:onDashBoardInitData, loading } = useQuery(GetLessonDataQuery)
    const checkData = useQuery(CheckLessonDataQuery)


    if(loading){
      store.commit('setLoading', true);
    }

    let loaded = ref(false)

    let updateMsg;
    let handIN;
    let idleMsg;
    let formMsg;
    let lessonEnds;
    let congratulations;
    let isAlreadyShowCong = ref(false);

    let idleTime = ref(0);
    let idleShowing = ref(false);
    let timerId;
    let timerId2;

    const routeViewModalRef = ref(null);

    const formMsgModalRef = ref(null);
    let hasShowForm = ref(false);

    const needShowCongModal = (async()=>{
      let lessonUnitsStatus = await computed(() => store.state.lessonUnitsStatus)
      let isAllCompete = true;
      if(lessonUnitsStatus.value.length){
        for(let i in lessonUnitsStatus.value){
          //console.log(lessonUnitsStatus.value[i])
          if(lessonUnitsStatus.value[i] !== 'completed'){
            isAllCompete = false;
          }
        }
        if(isAllCompete){
          if(!isAlreadyShowCong.value){
            if(congratulations){
              congratulations.show();
              isAlreadyShowCong.value = true;
            }
          }
        }
      }
    })

    
    onMounted(async()=>{
      await nextTick();
      
      store.commit('setLoading', false);
      //console.log('hihi')
      handIN = new bootstrap.Modal(document.getElementById('handInHW')) 
      idleMsg = new bootstrap.Modal(document.getElementById('idle-msg')) 
      formMsg = new bootstrap.Modal(document.getElementById('form-msg')) 
      lessonEnds = new bootstrap.Modal(document.getElementById('lessonEnds')) 
      congratulations = new bootstrap.Modal(document.getElementById('congratulations')) 
      updateMsg = new bootstrap.Modal(document.getElementById('updateMsg')) 

      if(handIN){
        if(store.state.showHandIn){
          handIN.show()
        }
      }

      if(store.state.lessonData){
        loaded.value = true;
        startCheckDataTimer();
      }

    })

    onBeforeUnmount(()=>{
      if(handIN)handIN.hide();
      if(lessonEnds)lessonEnds.hide();
      if(congratulations)congratulations.hide();
      if(updateMsg)updateMsg.hide();
      if(idleMsg)idleMsg.hide();
      if(formMsg)formMsg.hide();
    })

    onUnmounted(() => {
      clearInterval(timerId);
      clearInterval(timerId2);
    });

    watch(onDashBoardInitData, value => {
      store.commit('setData', value);
      //console.log('onDashBoardInitData loaded')
      //console.log(value)
      
      if(!loaded.value){
        loaded.value = true
        store.commit('setLoading', false);

        let lessonId = localStorage.getItem('lessonId');
        if(store.state.currentLesson?.id !== lessonId){
          router.push('/')
        }else {
          startCheckDataTimer();
          if(store.state.currentLesson?.studentStartTime === null){
            //console.log('go Home~~~');
            router.push('/')
          }else{
            for(let i in value.thisDevice?.currentLesson?.lessonUnits){
              if(value.thisDevice?.currentLesson?.lessonUnits[i].isActive){
                router.push('/dashboard/'+value.thisDevice?.currentLesson?.lessonUnits[i].type+'/'+i)
                break;
              }
            }
            checkShowHandIn();
          }
        }
      }
    })

    const refreshPage = ((time)=>{
      setTimeout(function(){location.reload(true)},time*1000);
    })

    const startCheckDataTimer = (()=>{
      if(!timerId){
        timerId = setInterval(async () => {
          await checkData.refetch();
          //console.log('gug2')
          const localLessonId = localStorage.getItem('lessonId');
          const localRefreshIndicator = localStorage.getItem('refreshIndicator');
          if(checkData.result.value.thisDevice?.currentLesson?.id != localLessonId){
            if(lessonEnds){
              clearInterval(timerId);
              router.push('/dashboard')
              lessonEnds.show();
              refreshPage(60);
            }
          }else if(checkData.result.value.thisDevice?.currentLesson === null){
            if(lessonEnds){
              clearInterval(timerId);
              router.push('/dashboard')
              lessonEnds.show();
              refreshPage(60);
            }
          }else if(checkData.result.value.thisDevice?.refreshIndicator !== localRefreshIndicator){
            clearInterval(timerId);
            router.push('/dashboard')
            localStorage.setItem('refreshIndicator', checkData.result.value.thisDevice?.refreshIndicator);
            updateMsg.show();
            refreshPage(5);
          }
          checkShowHandIn();
        }, Number(store.state.frequencyPollCurrentLesson)*1000);
      }
      if(!timerId2){
        timerId2 = setInterval(async () => {

          needShowCongModal();
          if(!idleShowing.value){
            if(idleTime.value >= store.state.studentIdleTimeLimit){ //
              idleMsg.show();
              idleShowing.value=true;
            }
            idleTime.value++;
          }

          //console.log('checking');
          //console.log(new Date());
          //console.log(new Date(store.state.showSurveyTime));
          const localFormLink = localStorage.getItem('formLink');
          if(localFormLink !== store.state.surveyUrl || localFormLink === null ){
            if(!hasShowForm.value){
              if(new Date() > new Date(store.state.showSurveyTime)){
                //console.log("show form");
                try{
                  if(route.path.includes('video')){
                    eventBus.triggerStopVideo();
                  }
                }catch(e){
                  console.log(e);
                }
                hasShowForm.value = true;
                formMsg.show();
                formMsgModalRef.value.startCountdown();
              }
            }
          }
        }, 1000);
      }
    })

    const resetIdleTime =(()=>{
      idleTime.value = 0;
    })

    const closeHandIn = (()=>{
      if(handIN){
        handIN.hide();
      }
    })

    const closeIdleMsg = (()=>{
      if(idleMsg){
        idleMsg.hide();
        idleTime.value = 0;
        idleShowing.value=false;
      }
    })

    const closeFormMsg = (()=>{
      if(formMsg){
        formMsg.hide();
        localStorage.setItem('formLink', store.state.surveyUrl);
      }
    })
    
    const closeCongratulations = (()=>{
      if(congratulations){
        congratulations.hide();
      }
    })

    function checkShowHandIn(){
      if(handIN){
        if(store.state.showHandIn){
          handIN.show()
        }else{
          handIN.hide()
        }
      }

    }

    return {
      route,
      loaded,
      resetIdleTime,
      closeHandIn,
      closeIdleMsg,
      closeFormMsg,
      closeCongratulations,
      msgData: computed(() => store.state.currentLesson?.homeworksToHandInThisLesson),
      msgData2: computed(() => store.state.currentLesson?.homeworksToHandInNextLesson),
      nextDate: computed(() => store.state.currentLesson?.nextLesson?.date),
      routeViewModalRef,
      formMsgModalRef,
      formLink: computed(() => store.state?.surveyUrl),
      formShowTime: computed(() => store.state?.surveyMinShowTime),
    };
  },
};
</script>

<style>

</style>
