<template>
    <div id="completeExercise" class="modal modal-msg-container fade" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="modal-msg-content">
              <div class="modal-msg-title">Complete this Exercise?</div>
              <div class="page-button black-style" @click="$emit('completeTask')" >Yes</div>
              <div class="page-button" @click="$emit('closeModal')">No</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ModalComponent',
    props: {
    }
  }
  </script>