<template>
    <div id="idle-msg" class="modal modal-msg-container fade" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
      <div class="black-background"></div>
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="modal-msg-content">
              <div class="modal-msg-icon"><img src="@/assets/images/times-up.png"></div>
              <div class="modal-msg-desc">
                <p>Please be reminded to mark the progress to continue</p>
              </div>
              <div class="page-button black-style" @click="$emit('closeModal')" >Close</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ModalComponent',
    props: {
    }
  }
  </script>

  <style scoped>
  .black-background{
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.5;
  }

  </style>