<template>
  <div id="login">
    <div id="login-container">
      <img class="banner" src="@/assets/images/logo.svg" />
      <p><button @click="logout">Logout</button></p>
    </div>
  </div>
</template>

<script>
  import { ref } from 'vue'
  import { useRouter } from 'vue-router'
  import {
    getAuth,
    signInWithEmailAndPassword,
    onAuthStateChanged
  } from 'firebase/auth'

  export default {
    setup( ){
      const router = useRouter()
      const auth = getAuth();
      //console.log(auth);
      const logout = () => { 
        auth.signOut()
        router.push('/')
      }

      return {logout}
    }
  }
</script>

<style scoped>

#login{
  position: relative;
  display: flex;
  height: 100vh;
  width: 100vw;
  align-items: flex-start;
  justify-content: center;
  background-color: #FFF;
}

#login-container{
  position: relative;
  display: block;
  text-align: center;
  width: 800px;
  margin-top: 80px;
  background-color: #FFF;
}

.banner{
  position: relative;
  display: block;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  margin-bottom: 40px;
}


</style>
