<template>
    <div id="startExercise" class="modal modal-msg-container fade" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="modal-msg-content">
              <div class="modal-msg-icon"><img src="@/assets/images/msg-exercise.png"></div>
              <div class="modal-msg-title">Start the Exercise?</div>
              <div class="modal-msg-desc">
                <p>Complete all questions in Math Topic Name One.Timer will be started after clicking “Start” button.Try to complete all questions within {{time}}min.</p>
              </div>
              <div class="page-button black-style" @click="$emit('startTask')" >start</div>
              <div class="page-button" @click="$emit('backHome')">cancel</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ModalComponent',
    props: {
      time:{
        type: Number,
        default: 0
      }
    }
  }
  </script>