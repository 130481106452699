<template>
    <div id="taskList" class="content-area" >
        <Transition mode="out-in">
            <div class="page-container" v-show="isShow">
                <div id="taskListCon" class="taskList-container">
                    <TaskItem v-for="(item, index) in taskItems" v-bind:key="'task-'+index" v-bind:id="'task'+index" :number="index" :type="item.type" :name="item.name" :status="taskItemsStatus[index]"/> 
                    <div class="space-area">a</div>
                </div>
            </div>
        </Transition>
    </div>
  </template>
  
  <script>
  import CircularProgressBar from '../../components/CircularProgressBar.vue';
  import TaskItem from './TaskItem.vue'; // Import the TaskItem component
  import { useStore } from 'vuex'; // If using Vuex
  import { computed, onMounted, nextTick, ref } from 'vue';

  export default {
    components: {
        CircularProgressBar,
        TaskItem
    },
    setup() {
            const store = useStore(); // If using Vuex
            //store.commit('setLoading', true);
            let taskItems =  computed(() => store.state.lessonUnits)
            let taskItemsStatus = computed(() => store.state.lessonUnitsStatus)
            let isShow = ref(false);

            onMounted(async () => {
                await nextTick();
                isShow.value = true
                setTimeout(function(){
                    let startIndex = -1;
                    for (let i = 0; i < taskItemsStatus.value.length; i++) {
                        if (taskItemsStatus.value[i] === 'start') {
                            startIndex = i;
                            break;
                        }
                    }
                    
                    if (startIndex === -1) {
                        for (let i = 0; i < taskItemsStatus.value.length; i++) {
                            if (taskItemsStatus.value[i] === 'continue') {
                            startIndex = i;
                            break;
                            }
                        }
                    }
                    let temp;
                    if (startIndex !== -1) {
                        temp = document.getElementById('task'+startIndex)
                    } else {
                        temp = document.getElementById('task0')
                    }
                    temp?.scrollIntoView({inline: "start", behavior: 'smooth' })
                },500)
            })

            return {
                taskItems,
                taskItemsStatus,
                isShow
            };
        },
    }
  </script>
  
  <style>
  
  #taskList{
      display: flex;
      align-items: center;
  }
  
  .taskList-container{
      position: relative;
      width: 1180px;
  
      -webkit-overflow-scrolling: touch;
      margin: 0 auto;
  
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 70px;
  }
  
  .notReady{
      filter: grayscale(100%);
      opacity: 0.7;
  }
  
  .taskItem{
      position: relative;
      margin-left: 120px;
      scroll-margin: 200px;
  }
  
  .taskItem-info{
      position: absolute;
      left: -25%;
      bottom: 50%;
      width: 185px;
      padding: 20px;
      z-index: 210;
      background: #ffffffd0;
  }
  
  .taskItem-info-num{
      position: relative;
      font-family: 'Montserrat';
      font-size: 60px;
      font-weight: 700;
      line-height: 73.14px;
      text-align: right;
  }
  
  .taskItem-info-type{
      position: relative;
      text-transform: uppercase;
      font-family: 'Montserrat';
      font-size: 16px;
      font-weight: 800;
      line-height: 19.5px;
      text-align: right;
      margin-block: 5px;
  }
  
  .yellow-color .taskItem-info-num,
  .yellow-color  .taskItem-info-type{
      color: #CDA448;
  }
  
  .red-color .taskItem-info-num{
      color: #D40000;
  }
  
  .red-color .taskItem-info-type{
      color: #7D2929;
  }
  
  .taskItem-info-name{
      position: relative;
      font-family: 'Montserrat';
      font-size: 24px;
      font-weight: 800;
      line-height: 29.26px;
      text-align: right;
  }
  
  .taskItem-type-circle{
      position: relative;
  }
  
  .taskItem-type-img-bg,
  .taskItem-type-img{
      position: relative;
      width: 240px;
      z-index: 200;
  }
  
  .taskItem-type-img-bg{
      position: absolute;
      right: -20px;
      bottom: -20px;
      z-index: 180;
      display: none;
  }
  
  .notReady .taskItem-type-img-bg,
  .start .taskItem-type-img-bg{
      display: block;
  }
  
  .taskItem-btn{
      position: absolute;
      right: -10px;
      bottom: -10px;
      z-index: 270;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 82px;
      height: 82px;
      color: #FFF;
      text-transform: uppercase;
      font-family: 'Montserrat';
      font-size: 12px;
      font-weight: 600;
      line-height: 14.63px;
      border-radius: 100%;
      text-align: center;
      text-decoration: none;
      cursor: pointer;
  }
  
  .taskItem-btn::after{
      content: ' ';
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 86px;
      height: 86px;
      border-radius: 100%;
      border: 4px solid #ffffff;
  }
  
  .start .taskItem-btn{
      background-color: #000;
  }
  
  .continue .red-color.taskItem-btn,
  .completed .red-color.taskItem-btn{
      background-color: #7D2929;
  }

  .continue .yellow-color.taskItem-btn,
  .completed .yellow-color.taskItem-btn{
      background-color: #CDA448;
  }
  
  .taskItem-progress{
      position: absolute;
      top: -10px;
      left: -10px;
  }
  
  .taskItem-status{
      position: absolute;
      right: -44%;
      top: 108%;
      z-index: 500;
      width: 220px;
      padding-top: 20px;
      padding-left: 20px;
      border-left: 1px solid #CDCDCD;
  }
  
  .taskItem-completed-rate{
      position: relative;
      font-family: 'Montserrat';
      font-size: 40px;
      font-weight: 700;
      line-height: 48.76px;
      text-align: left;
  }
  
  .taskItem-completed-text{
      position: relative;
      font-family: 'Montserrat';
      font-size: 12px;
      font-weight: 700;
      line-height: 14.63px;
      text-align: left;
      text-transform: uppercase;
      margin-bottom: 20px;
  }
  
  .yellow-color .taskItem-completed-rate,
  .yellow-colo .taskItem-completed-text{
      color: #A58245;
  }
  
  
  .red-color .taskItem-completed-rate{
      color: #D40000;
  }
  
  .red-color .taskItem-completed-text{
      color: #7D2929;
  }
  
  .taskItem-time-total-spent,
  .taskItem-time-avg-spent{
      position: relative;
      display: inline-block;
      font-family: 'Comfortaa';
      font-size: 12px;
      font-weight: 700;
      line-height: 13.38px;
      text-align: left;
      color: #22222276;
  }
  
  .taskItem-time-spent-min{
      position: relative;
      display: inline;
      font-family: 'Comfortaa';
      font-size: 12px;
      font-weight: 700;
      line-height: 13.38px;
      text-align: left;
      color: #222;
  }

  .space-area{
    position: relative;
    margin-left: 1000px;
    opacity: 0;
  }
  
  </style>