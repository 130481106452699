<template>
    <div id="form-msg" class="modal modal-msg-container fade" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
      <div class="black-background"></div>
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="modal-msg-content">
              <div class="modal-content-header">
                <div class="modal-msg-icon"><img src="@/assets/images/Message.gif"></div>
                <div class="modal-msg-desc">
                  Please fill in the feedback form below before your class ends
                </div>
              </div>
                <div class="iframe-container">
                  <iframe
                    width="100%"
                    height="400"
                    :src="formLink"
                    frameborder="0"
                    allowfullscreen
                    class="iframe-style"
                  ></iframe>
                </div>
              <div :class="availableClose? '':'dim'" class="page-button black-style" @click="$emit('closeModal')" >
                Close<template v-if="!availableClose">({{  countDownTime }}s)</template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
<script>
  export default {
    name: 'ModalComponent',
    props: {
      formLink:{
        type: String
      },
      formShowTime:{
        type: Number
      }
    },
    data(){
      return {
        countDownTime:0,
        availableClose: false
      };
    },
    methods: {
      startCountdown() {
        this.countDownTime = this.formShowTime;
        this.intervalId = setInterval(() => {
          if (this.countDownTime <= 0) {
            this.availableClose = true;
            clearInterval(this.intervalId);
          } else {
            this.countDownTime--;
          }
        }, 1000);
      }
    }
  }
</script>

<style scoped>

.dim{
  opacity: 0.5;
  pointer-events: none;
}

#form-msg .modal-dialog{
  min-width: 900px !important;
}

.modal-content-header{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}

.modal-msg-icon{
  width: 100px;
  margin-bottom: 0px;
  margin-left: -25px;
}

.modal-msg-desc{
  font-size: 14px;
  margin-top: 15px;
}

.black-background{
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.5;
}

.iframe-container {
  position: relative;
  width: 100%;
  height: 400px;
  overflow: hidden;
  margin-bottom: 20px;
}

.iframe-style {
  width: 100%;
  height: 100%;
}

</style>