<template>
    <div id="saveVideo" class="modal modal-msg-container fade" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="modal-msg-content">
              <div class="modal-msg-icon"><img src="@/assets/images/msg-save.png"></div>
              <div class="modal-msg-title">Start the next task without finishing video?</div>
              <div class="modal-msg-desc">
                <p>You haven’t completed all the video yet.Do you really want to quit this video task?</p>
                <p>You progress will be saved after leaving.</p>
              </div>
              <div class="page-button black-style" @click="$emit('closeModal')" >back to video</div>
              <div class="page-button" @click="$emit('saveTask')">Save the progress</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ModalComponent',
    props: {
    }
  }
  </script>