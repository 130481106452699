<template>
    <div id="lessonEnds" class="modal modal-msg-container long-msg fade" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
      <div class="black-background"></div>
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="modal-msg-content">
              <div class="modal-msg-icon"><img src="@/assets/images/times-up.png"></div>
              <div class="modal-msg-title">Lesson Ends</div>
              <div class="modal-msg-desc">
                <p>Your session is finished today.<br/>You can review your progress with the tutor.</p>
                <p v-if="nextDate !== undefined&& msgData?.length>0">Please submit the following assignments by <span class="org">{{ nextDate }}</span> :</p>
              </div>
              <ul class="assignments-list" v-if="nextDate !== undefined">
                <li v-for="(msg,index) in msgData" v-bind:key="'msg'+index">
                  <p class="assignments-list-name">{{ msg.name }}</p>
                  <p class="assignments-list-instruction">( {{ msg.instruction }} )</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ModalComponent',
    props: {
      msgData:{
        type: Object
      },
      nextDate:{
        type: String
      }
    }
  }
  </script>

  <style scoped>
  .org{
    color: #BF5214;
  }
  
  .black-background{
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.5;
  }

  </style>