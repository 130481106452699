import { createStore } from 'vuex';

const store = createStore({
  state: {
    isLoading: false,
    lessonData: null,
    frequencySendVideoPlayheadTime: 10,
    frequencySyncLessonUnitTimeRemaining: 10,
    frequencyPollCurrentLesson: 10,
    showSurveyTime: null,
    surveyUrl: null,
    surveyMinShowTime: 0,
    studentIdleTimeLimit: 1500,
    currentLesson: null,
    student: null,
    lessonUnits: null,
    showHandIn: false,
    lessonId: null,
    lessonEnd: false,
    lessonUnitsStatus: []
  },
  mutations: {
    setLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    setData(state, lessonData) {
      state.lessonData = lessonData;
      state.frequencySendVideoPlayheadTime = lessonData.config.frequencySendVideoPlayheadTime;
      state.frequencySyncLessonUnitTimeRemaining = lessonData.config.frequencySyncLessonUnitTimeRemaining;
      state.frequencyPollCurrentLesson = lessonData.config.frequencyPollCurrentLesson;
      state.studentIdleTimeLimit = lessonData.config.studentIdleTimeLimit;
      state.showSurveyTime = lessonData.thisDevice.showSurveyTime;
      state.surveyUrl = lessonData.thisDevice.surveyUrl;
      state.surveyMinShowTime = lessonData.thisDevice.surveyMinShowTime;
      state.currentLesson = lessonData.thisDevice.currentLesson;
      state.student = lessonData.thisDevice.currentLesson?.student;
      state.lessonUnits = lessonData.thisDevice.currentLesson?.lessonUnits;
      state.lessonId = lessonData.thisDevice.currentLesson?.id;
      state.showHandIn = lessonData.thisDevice.currentLesson?.homeworksHandedIn;
      
      let hasStartOrContinue = false;
      state.lessonUnitsStatus = [];
      for(let i = 0; i < state.lessonUnits?.length; i++) {
        if(state.lessonUnits[i].type !== 'test' && state.lessonUnits[i].markAsCompleted){
            state.lessonUnitsStatus.push("completed");
        }else if(state.lessonUnits[i].type === 'test' && state.lessonUnits[i].hasSubmitted){
            state.lessonUnitsStatus.push("completed");
        }else if(state.lessonUnits[i].startTime === null){
          if(!hasStartOrContinue){
            if(state?.lessonUnitsStatus[state.lessonUnitsStatus.length-1]==='completed' || i===0){
              state.lessonUnitsStatus.push("start");
              hasStartOrContinue = true;
            }else{
              state.lessonUnitsStatus.push("notReady");
            }
          }else{
            state.lessonUnitsStatus.push("notReady");
          }
        }else{
          state.lessonUnitsStatus.push("continue");
        }
      }
    },
    updateData(state, payload){
      console.log('updateData')
      //console.log(payload.data);
      //console.log(payload.indexId);
      //state.lessonUnits[payload.indexId] = payload.data;
      if (payload.indexId >= 0 && payload.indexId < state.lessonUnits.length) {
        const lessonUnitsCopy = [...state.lessonUnits];
        lessonUnitsCopy[payload.indexId] = payload.data;
        state.lessonUnits = lessonUnitsCopy;
      }
      
      let hasStartOrContinue = false;
      state.lessonUnitsStatus = [];
      for(let i = 0; i < state.lessonUnits?.length; i++) {
        if(state.lessonUnits[i].type !== 'test' && state.lessonUnits[i].markAsCompleted){
            state.lessonUnitsStatus.push("completed");
        }else if(state.lessonUnits[i].type === 'test' && state.lessonUnits[i].hasSubmitted){
            state.lessonUnitsStatus.push("completed");
        }else if(state.lessonUnits[i].startTime === null){
          if(!hasStartOrContinue){
            if(state?.lessonUnitsStatus[state.lessonUnitsStatus.length-1]==='completed' || i===0){
              state.lessonUnitsStatus.push("start");
              hasStartOrContinue = true;
            }else{
              state.lessonUnitsStatus.push("notReady");
            }
          }else{
            state.lessonUnitsStatus.push("notReady");
          }
        }else{
          state.lessonUnitsStatus.push("continue");
        }
      }
    },
    updateCheckedQuestions(state, payload){
      const lessonUnitsCopy = [...state.lessonUnits];
      const lessonUnitCopy = { ...lessonUnitsCopy[payload.indexId] };
      lessonUnitCopy.checkedQuestionIndexNos = payload.checkedQuestionIndexNos;
      lessonUnitsCopy[payload.indexId] = lessonUnitCopy;
      state.lessonUnits = lessonUnitsCopy;
    },
    setLessonStartTime(state, startTime) {
      state.currentLesson = {
        ...state.currentLesson,
        studentStartTime: startTime
      };
    }
  },
});

export default store;