<template>
    <div class="progress-bar" :style="{ width: size, height: size }">
      <div class="progress" :style="{ backgroundImage: progressGradient }"></div>
      <div class="inner-circle" :style="{ width: innerSize, height: innerSize }"></div>
      <slot></slot>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      progress: {
        type: Number,
        default: 0,
      },
      size: {
        type: String,
        default: '100px',
      },
      trackColor: {
        type: String,
        default: '#e0e0e0',
      },
      progressColor: {
        type: String,
        default: '#007bff',
      },
      ringThickness: {
        type: Number,
        default: 20, // Percentage of the total size
      },
    },
    computed: {
      innerSize() {
        return `calc(${this.size} - ${this.ringThickness}%)`;
      },
      progressGradient() {
        return `conic-gradient(${this.progressColor} ${this.progress * 3.6}deg, ${this.trackColor} 0deg)`;
      },
    },
  };
  </script>
  
  <style scoped>
  .progress-bar {
    border-radius: 50%;
    background-color: v-bind(trackColor);
    position: relative;
    overflow: hidden;
  }
  
  .progress {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-image: v-bind(progressGradient);
    position: absolute;
    clip: rect(0, 100%, 100%, 50%);
  }
  
  .inner-circle {
    border-radius: 50%;
    background-color: white; /* Or any desired background color */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  </style>