<template>
  <div
    id="startLesson"
    class="modal modal-msg-container fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <div class="modal-msg-content">
            <div class="modal-msg-title">Student for this seat:</div>
            <div class="modal-msg-desc">
              <p>{{ name }}</p>
              <p></p>
              <!-- Dynamically insert the first word into the confirmation message -->
              <p>Please type <span class="first-word">{{ firstWord }}</span> below to confirm</p>
            </div>

            <!-- Input field for typing the name with dynamic placeholder -->
            <input
              type="text"
              v-model="inputName"
              @input="validateName"
              @keyup.enter="startLesson"
              class="form-control mb-2"
              :placeholder="'Type ' + firstWord + '...'"/> <!-- Dynamic placeholder with first word -->

            <!-- Error message if the name doesn't match -->
            <p class="text-danger red"><span v-if="showError">Does not match!</span></p>

            <!-- Disable 'Start Lesson' button if the name doesn't match -->
            <div
              class="page-button black-style"
              :class="{ disabled: !isNameValid }"
              @click="startLesson"
            >
              Start Lesson
            </div>
            <div class="page-button" @click="$emit('closeModal')">Cancel</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalComponent',
  props: {
    name: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      inputName: '', // Stores the user input
      isNameValid: false, // Flag to track if the input is valid
      showError: false, // Flag to show/hide the error message
    };
  },
  computed: {
    // Compute the first word of the name for dynamic use in the message and placeholder
    firstWord() {
      return this.name.split(' ')[0].toLowerCase(); // Get the first word of the name
    },
  },
  methods: {
    // Extract the first word from the name and validate against the input
    validateName() {
      const sanitizedFirstWord = this.firstWord.toLowerCase(); // First word of the name, case insensitive
      const sanitizedInput = this.inputName.trim().toLowerCase(); // Sanitize input (trim spaces and lower case)

      // Check if the input matches the first word of the name
      this.isNameValid = sanitizedInput === sanitizedFirstWord;

      // Show error message if the input doesn't match the first word and input is not empty
      this.showError = !this.isNameValid && this.inputName.length > 0;
    },
    // Emit the startLesson event if the name is valid
    startLesson() {
      if (this.isNameValid) {
        this.$emit('startLesson');
      }
    },
  },
};
</script>

<style scoped>
.red {
  color: #d40000;
}
.disabled {
  pointer-events: none;
  opacity: 0.5;
}
.text-danger{
  min-height: 20px;
}

.modal-msg-desc{
  font-weight: 400;
}

.first-word{
  font-size: 1.2rem;
  font-weight: 600;
  color: #A58245;
}
</style>