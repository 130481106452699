<template>
  <div class="loading-screen">
    <div class="loading-screen-overlay"></div>
    <div class="loading-screen-content">
      <div class="loading-spinner"></div>
    </div>
  </div>
</template>


<script>
export default {
  name: "loading"
}
</script>

<style>

.loading-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    pointer-events: auto;
}
  
.loading-screen-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.7;
    backdrop-filter: blur(5px);
}
  
.loading-screen-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
  
 @keyframes spinner {
    to { transform: rotate(360deg); }
}

.loading-spinner {
    width: 40px;
    height: 40px;
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #7983ff;
    border-radius: 50%;
    animation: spinner 1s linear infinite;
}

</style>