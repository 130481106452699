<template>
    <div id="saveExercise" class="modal modal-msg-container fade" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="modal-msg-content">
              <div class="modal-msg-icon"><img src="@/assets/images/msg-save.png"></div>
              <div class="modal-msg-title">Next task?</div>
              <div class="modal-msg-desc">
                <p>You haven’t completed all questions here.<br/>Do you really want to proceed to next task?</p>
              </div>
              <div class="page-button black-style" @click="$emit('completeTask')" >Next task</div>
              <div class="page-button" @click="$emit('closeModal')">Cancel</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ModalComponent',
    props: {
    }
  }
  </script>