<template>
    <div id="pageExercise" class="content-area" :class="isLessThan15Min?'warning':''" >
      <div class="page-container">
            <transition>
                <div class="page-content" v-show="loaded">
                    <div class="page-type">Exercise</div>
                    <div class="page-title">{{ taskData.name }}</div>
                    <div class="page-content-area">
                    <div class="page-desc"><i class="bi bi-exclamation-circle desc-icon-exclamation"></i>Instruction: {{ taskData.instruction.replace("###name###", taskData.name) }}</div>
                    <div class="page-text-exe-area">
                            <div class="page-timer-area">
                                <div class="timer-desc">Try to complete within {{ Math.floor(taskData.timeLimit/60) }}min</div>
                                <div class="timer-content">
                                    <div class="timer-title">Time Left</div>
                                    <div class="timer-text">{{ formatTime(timerValue) }}</div>
                                    <div class="page-button timer-pause" @click="openPauseExerciseModal"><i class="bi bi-pause-circle"></i><div class="a"><span class="b">PAUSE</span><br>for 5 min</div></div>
                                    <div class="timer-progress">
                                        <circular-progress-bar :progress="progressValue" size="250px" :ring-thickness="8" trackColor="#F2F2F2" progressColor="#CDA448" />
                                    </div>
                                </div>
                                <div class="page-completed-info">
                                    <div class="page-completed-title">completed</div>
                                    <div class="page-completed-count"><span>{{ checkedQuestions.length }}</span>/{{ taskData.instructQuestionIndexNos.length }}</div>
                                </div>
                            </div>
                            <div class="page-ans-area">
                                <div class="ans-desc">Tap the question number that you have completed. </div>
                                <div class="ans-content">
                                    <div class="ans-item" v-for="questionId in taskData.instructQuestionIndexNos" v-bind:key="'ans-item-'+questionId" 
                                    v-bind:class="checkedQuestions.includes(String(questionId))? 'checked':''" @click="toggleQuestion(String(questionId))">
                                        <i class="bi " v-bind:class="checkedQuestions.includes(String(questionId))? 'bi-check-circle':'bi-circle'"></i>{{ questionId+1 }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="page-content-bottom">
                    <!--<div class="page-button" @click="openQuitExerciseModal"><i class="bi bi-house-door"></i>Back to home</div>-->
                    <div class="page-button-right">
                        <div class="page-button" @click="markAllDone">Mark all as done</div>
                        <div class="page-button black-style space-left" @click="checkSave" >
                        <i class="bi bi-check-circle"></i>Next Task
                        </div>
                    </div>
                    </div>
                </div>
            </transition>
        </div>
        <StartExerciseModal :time="Math.floor(taskData.timeLimit/60)" @startTask="startTask" @backHome="goToHome"/>
        <PauseExerciseModal ref="pauseExerciseModalRef" :pauseTime="300" @closeModal="closePauseExerciseModal"/>
        <QuitExerciseModal @backHome="backToHomeModal" @closeModal="closeQuitExerciseModal"/>
        <SaveExerciseModal @completeTask="completeTaskModal" @closeModal="closeSaveExerciseModal"/>
        <CompleteExerciseModal @completeTask="completeTaskModal" @closeModal="closeCompleteExerciseModal"/>
    </div>
</template>
  
<script>
import CircularProgressBar from '../../components/CircularProgressBar.vue';

import StartExerciseModal from '@/components/modal/StartExercise.vue';
import PauseExerciseModal from '@/components/modal/PauseExercise.vue';
import QuitExerciseModal from '@/components/modal/QuitExercise.vue';
import SaveExerciseModal from '@/components/modal/SaveExercise.vue';
import CompleteExerciseModal from '@/components/modal/CompleteExercise.vue';

import { useStore } from 'vuex'; 
import { computed, nextTick, onMounted, onUnmounted, ref, getCurrentInstance } from 'vue';
import { useRoute, useRouter } from 'vue-router'
import { useQuery, useMutation } from '@vue/apollo-composable'

import StartLessonUnitMutation from '../../components/gql/StartLessonUnit.gql'
import MarkLessonunitAsCompletedMutation from '../../components/gql/MarkLessonunitAsCompleted.gql'
import CompleteLessonunitQuestionMutation from '../../components/gql/CompleteLessonunitQuestion.gql'
import CompleteAllLessonunitQuestionsMutation from '../../components/gql/CompleteAllLessonunitQuestions.gql'
import SyncLessonunitTimeRemainingMutation from '../../components/gql/SyncLessonunitTimeRemaining.gql'
import SyncLessonunitTimeRemainingWithDataMutation from '../../components/gql/SyncLessonunitTimeRemainingWithData.gql'



export default {
    components: {
        CircularProgressBar,
        StartExerciseModal,
        PauseExerciseModal,
        QuitExerciseModal,
        SaveExerciseModal,
        CompleteExerciseModal,
    },
    setup(){
        const route = useRoute()
        const router = useRouter()
        const store = useStore()

        const instance = getCurrentInstance();

        // Define the resetTime method
        const resetTime = () => {
            if (instance) {
                instance.emit('resetTime');
            }
        };

        let loaded = ref(false);

        let interval = ref();
        let isPause = ref(false);
        let syncTime = 0;

        let startExerciseModal;
        let pauseExerciseModal;
        let quitExerciseModal;
        let saveExerciseModal;
        let completeExerciseModal;
        const pauseExerciseModalRef = ref(null);

        const isLessThan15Min = ref(false);

        const taskId = route.params.id;
        const taskData = computed(() =>store.state.lessonUnits[taskId]);
        const isPageStart = computed(() =>store.state.currentLesson.studentStartTime);
        
        let checkedQuestions = ref([]);
        for(let q in taskData.value.checkedQuestionIndexNos){
            let temp = taskData.value.checkedQuestionIndexNos[q];
            checkedQuestions.value.push(String(temp));
        }

        let timerValue = ref(taskData.value.timeRemaining);
        let progressValue = ref(Math.floor(((taskData.value.timeLimit - timerValue.value)/taskData.value.timeLimit)*100));

        const { mutate:startLessonUnit, onDone:onStartLessonUnitDone  } = useMutation(StartLessonUnitMutation, 
            () => ({      
                variables:{
                    startLessonunitId: String(taskData.value.id)
                }
            })
        )

        const { mutate:syncLessonunitTimeRemaining } = useMutation(SyncLessonunitTimeRemainingMutation, 
            () => ({      
                variables:{
                    syncLessonunitTimeRemainingId: String(taskData.value.id),
                    timeRemaining: timerValue.value
                }
            })
        )

        const { mutate:SyncLessonunitTimeRemainingWithData, onDone:quitTask } = useMutation(SyncLessonunitTimeRemainingWithDataMutation, 
            () => ({      
                variables:{
                    syncLessonunitTimeRemainingId: String(taskData.value.id),
                    timeRemaining: timerValue.value
                }
            })
        )
        
        const { mutate:completeLessonunitQuestion} = useMutation(CompleteLessonunitQuestionMutation)

        const { mutate:completeAllLessonunitQuestions } = useMutation(CompleteAllLessonunitQuestionsMutation, 
            () => ({      
                variables:{
                    completeAllLessonunitQuestionsId: String(taskData.value.id),
                }
            })
        )

        const { mutate: competeExercise, onDone: onCompeteExerciseDone } = useMutation(MarkLessonunitAsCompletedMutation, 
            () => ({      
                variables:{
                    markLessonunitAsCompletedId: String(taskData.value.id)
                }
            })
        )

        onStartLessonUnitDone((res) => {
            if(res.data.startLessonunit.success){
                store.commit('setLoading', false);
                store.commit('updateData', {data:res.data.startLessonunit.lessonunit, indexId:taskId});
                startTimer();
            }else{
                if(route.meta.type === 'exercise'){
                    openStartExerciseModal();
                }
            }
        })

        onCompeteExerciseDone((res) => {
            //console.log(res);
            store.commit('setLoading', false);
            if(res.data.markLessonunitAsCompleted.success){
                store.commit('updateData', {data:res.data.markLessonunitAsCompleted.lessonunit, indexId:taskId});
                goToHome();
            }
        })

        quitTask((res) => {
            //console.log(res);
            store.commit('setLoading', false);
            if(res.data.syncLessonunitTimeRemaining.success){
                store.commit('updateData', {data:res.data.syncLessonunitTimeRemaining.lessonunit, indexId:taskId});
                goToHome();
            }
        })
                
        const toggleQuestion = (questionId) => {
            //console.log(questionId);
            resetTime();
            let isComplete;
            if (checkedQuestions.value.includes(questionId)) {
                checkedQuestions.value = checkedQuestions.value.filter((id) => String(id) !== questionId);
                isComplete = false;
            } else {
                checkedQuestions.value.push(questionId);
                isComplete = true;
            }
            completeLessonunitQuestion({
                completeLessonunitQuestionId: String(taskData.value.id),
                questionIndexNo: Number(questionId),
                isComplete: isComplete
            })
            store.commit('updateCheckedQuestions',{indexId:taskId, checkedQuestionIndexNos:checkedQuestions.value})
        };

        const formatTime = (timeInSeconds) => {
            const sign = timeInSeconds < 0 ? "-" : "";
            const absoluteTime = Math.abs(timeInSeconds);

            const hours = Math.floor(absoluteTime / 3600);
            const minutes = Math.floor((absoluteTime % 3600) / 60);
            const seconds = absoluteTime % 60;

            return `${sign}${hours}:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
        };

        function startTask(){
            store.commit('setLoading', true);
            closeStartExerciseModal();
            startLessonUnit();
        }

        function startTimer(){
            loaded.value = true;
            interval.value = setInterval(() => {
                if(!isPause.value) {
                    timerValue.value--;
                    syncTime++;
                }
                progressValue.value = Math.floor(((taskData.value.timeLimit - timerValue.value)/taskData.value.timeLimit)*100);
                if(syncTime === store.state.frequencySyncLessonUnitTimeRemaining){
                    syncLessonunitTimeRemaining();
                    syncTime = 0;
                }
                if(timerValue.value<900){
                    isLessThan15Min.value = true
                }
            }, 1000);
        }

        const openStartExerciseModal = (()=>{
            resetTime();
            if(startExerciseModal){
                startExerciseModal.show();
            }
        })

        
        const openPauseExerciseModal = (()=>{
            resetTime();
            if(pauseExerciseModal){
                isPause.value = true;
                pauseExerciseModalRef.value.startCountdown();
                pauseExerciseModal.show();
            }
        })

        const openQuitExerciseModal = (()=>{
            if(quitExerciseModal){
                quitExerciseModal.show();
            }
        })

        const openSaveExerciseModal = (()=>{

            if(saveExerciseModal){
                saveExerciseModal.show();
            }
        })

        const openCompleteExerciseModal = (()=>{
            if(completeExerciseModal){
                completeExerciseModal.show();
            }
        })

        const closeStartExerciseModal = (()=>{
            resetTime();
            if(startExerciseModal){
                startExerciseModal.hide();
            }
        })

        const closePauseExerciseModal = (()=>{
            resetTime();
            if(pauseExerciseModal){
                isPause.value = false;
                pauseExerciseModal.hide();
            }
        })

        const closeQuitExerciseModal = (()=>{
            if(quitExerciseModal){
                quitExerciseModal.hide();
            }
        })

        const closeSaveExerciseModal = (()=>{
            resetTime();
            if(saveExerciseModal){
                saveExerciseModal.hide();
            }
        })

        const closeCompleteExerciseModal = (()=>{
            resetTime();
            if(completeExerciseModal){
                completeExerciseModal.hide();
            }
        })

        const goToHome = (()=>{
            router.push({path:'/dashboard'})
        })

        const backToHomeModal = (()=>{
            resetTime();
            closeSaveExerciseModal();
            closeQuitExerciseModal();
            store.commit('setLoading', true);
            SyncLessonunitTimeRemainingWithData();
        })

        const completeTaskModal = (()=>{
            closeSaveExerciseModal();
            closeCompleteExerciseModal();
            store.commit('setLoading', true);
            competeExercise();
        })

        const markAllDone = (()=>{
            resetTime();
            if(checkedQuestions.value.length !== taskData.value.instructQuestionIndexNos.length){
                checkedQuestions.value = [];
                for(let q in taskData.value.instructQuestionIndexNos){
                    const value = taskData.value.instructQuestionIndexNos[q];
                    checkedQuestions.value.push(String(value));
                }
                completeAllLessonunitQuestions();
                store.commit('updateCheckedQuestions',{indexId:taskId, checkedQuestionIndexNos:checkedQuestions.value})
            }
        })

        const checkSave = (()=>{
            resetTime();
            if(checkedQuestions.value.length !== taskData.value.instructQuestionIndexNos.length){
                openSaveExerciseModal();
            }else{
                openCompleteExerciseModal();
                //store.commit('setLoading', true);
                //competeExercise();
            }
        })

        const checkIsDone= computed(()=>{
            if(checkedQuestions.value.length !== taskData.value.instructQuestionIndexNos.length){
                return false;
            }else{
                return true;
            }
        })


        onMounted(async()=>{
            await nextTick();
            console.log()
            resetTime();
            startExerciseModal = new bootstrap.Modal(document.getElementById('startExercise')) 
            pauseExerciseModal = new bootstrap.Modal(document.getElementById('pauseExercise')) 
            quitExerciseModal = new bootstrap.Modal(document.getElementById('quitExercise')) 
            saveExerciseModal = new bootstrap.Modal(document.getElementById('saveExercise')) 
            completeExerciseModal = new bootstrap.Modal(document.getElementById('completeExercise')) 
            if(isPageStart.value !== null){
                if(route.meta.type === 'exercise'){
                    if(taskData.value.startTime === null){
                        openStartExerciseModal();
                    }else{
                        startTimer();
                    }
                }
            }
        })

        onUnmounted(()=>{
            closeStartExerciseModal();
            closeQuitExerciseModal();
            closeSaveExerciseModal();
            closeCompleteExerciseModal();
            if (interval) {
                clearInterval(interval.value);
            }
        })

        return{
            loaded,
            taskData,
            checkedQuestions,
            timerValue,
            formatTime,
            progressValue,
            toggleQuestion,
            goToHome,
            startTask,
            markAllDone,
            checkSave,
            checkIsDone,
            backToHomeModal,
            openQuitExerciseModal,
            openPauseExerciseModal,
            completeTaskModal,
            closeQuitExerciseModal,
            closeSaveExerciseModal,
            closeCompleteExerciseModal,
            closePauseExerciseModal,
            pauseExerciseModalRef,
            isLessThan15Min
        }

    },
}
</script>

<style scoped>


#pageExercise.warning{
    background: #F2E9E9;
}

.warning .page-type{
    color: #7D2929;
}

.warning .page-desc{
    border: 1px solid #7D2929;
    background-color: #d4000044;
}

.warning .page-completed-title{
    color: #7D2929;
}

.warning .page-completed-count span
{
    color: #D40000;
}

.warning .page-button{
    color: #7D2929;
    border: 2px solid #7D2929;
}

.warning .page-button.black-style{
    color: #FFF;
}

#pageExercise{
    background: #F9F9F9;
}

.page-text-exe-area{
    position: relative;
    display: flex;
    flex-direction: row;
    background: #FFF;
}

.page-timer-area{
    position: relative;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    text-align: center;
    width: 42%;
    margin: 10px 0px;
    border-right: 1px solid #CDCDCD;
}

.ans-desc,
.timer-desc{
    position: relative;
    font-family: 'Comfortaa';
    font-size: 12px;
    font-weight: 400;
    line-height: 13.38px;
    color: #000;
    margin-top: 20px;
    margin-bottom: 20px;
}

.timer-content{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 250px;
    height: 250px;
    margin: 0 auto;
}

.timer-progress{
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 400;
}

.timer-title{
    position: relative;
    font-family: 'Montserrat';
    font-size: 16px;
    font-weight: 700;
    line-height: 19.5px;
    text-align: center;
    color: #A58245;
    text-transform: uppercase;
    z-index: 500;
}

.timer-text{
    position: relative;
    font-family: 'Montserrat';
    font-size: 40px;
    font-weight: 700;
    line-height: 48.76px;
    letter-spacing: 2px;
    text-align: center;
    color: #000;
    margin-bottom: 10px;
    z-index: 500;
}

.timer-pause{
    margin: 0 auto;
    padding: 10px 15px;
    width: fit-content;
    z-index: 500;
}

.timer-pause .a{
    position: relative;
    text-align: left;
    font-size: 12px;
    margin-left: 0px;
}

.timer-pause .b{
    font-size: 16px;
}

.page-completed-info{
    position: relative;
    margin-top: 20px;
    margin-bottom: 20px;
}

.page-completed-title{
    position: relative;
    font-family: 'Montserrat';
    font-size: 12px;
    font-weight: 700;
    line-height: 14.63px;
    text-align: center;
    color: #A58245;
    text-transform: uppercase;
    margin-bottom: 5px;
}

.page-completed-count{
    position: relative;
    font-family: 'Montserrat';
    font-size: 24px;
    font-weight: 600;
    line-height: 29.26px;
    text-align: center;
    color: #AAAAAA;
}

.page-completed-count span{
    font-family: 'Montserrat';
    font-size: 40px;
    font-weight: 700;
    line-height: 48.76px;
    color: #A58245;
}

.page-ans-area{
    position: relative;
    width: 58%;
    padding-left: 20px;
}

.ans-content{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    max-height: 365px;
    overflow-y: scroll;
}

.ans-item{
    position: relative;
    display: flex;
    align-content: center;
    justify-content: space-around;
    text-align: center;
    width: 18%;
    padding: 10px 5px;
    margin-bottom: 10px;
    border-radius: 100px;
    border: 1px solid #989898;
    cursor: pointer;
    margin-right: 5px;
}

.ans-item.checked{
    background-color: #000;
    color: #FFF;
}

.ans-item.checked i{
    color: #CDA448;
}

.ans-item i{
    color: #989898;
    width: 24px;
    height: 24px;
}

.not-ready{
    position: relative;
    opacity: 0.3;
}
</style>
  