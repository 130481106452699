<template>
  <LandingMsgModal :msgData="msgData" />
  <StartLessonModal :name="student" @closeModal="closeStartLessonMsg" @startLesson="confirmStarLesson"/>
  <div id="landing">
    
    <Transition>
      <div v-if="!loading" id="main-msg">
          <img class="landing-logo" src="@/assets/images/logo.svg" />
          <template v-if="student !== undefined">
            <div class="msg-name">Hi, {{student}}</div>
            <div class="msg-text">Welcome to the Bean’s Math StudyLog.Tap the button and start your lesson today!</div>
            <a class="button-style black" @click="clickStarLesson"><div class="button-text">Start Lesson Now</div></a>
          </template>
          <template v-else>
            <div class="msg-name">Please refresh the page</div>
          </template>
      </div>
    </Transition>
  </div>
</template>
  
<script>
import { onMounted, onUnmounted, onBeforeUnmount, watch, ref } from 'vue'
import { useQuery, useMutation } from '@vue/apollo-composable'
import { useStore } from 'vuex';
import { computed } from 'vue';
import { useRouter } from 'vue-router';

import GetLessonDataQuery from '../../components/gql/GetLessonData.gql'
import StartLessonMutation from '../../components/gql/StartLesson.gql'
import CheckLessonDataQuery from '../../components/gql/CheckLessonData.gql'

import LandingMsgModal from '@/components/modal/LandingMsg.vue';
import StartLessonModal from '@/components/modal/StartLesson.vue';
export default {
  name: 'Landing',
  components: {
    LandingMsgModal,
    StartLessonModal
  },
  setup() {
    const { result, loading } = useQuery(GetLessonDataQuery)
    const checkData = useQuery(CheckLessonDataQuery)

    const store = useStore()
    const router = useRouter()

    const msgData = ref('')
    
    let timerId;
    let landingMsg;
    let startLessonMsg;
    if(loading){
      store.commit('setLoading', true);
    }

    onMounted(()=>{
      landingMsg = new bootstrap.Modal(document.getElementById('landingMsg')) 
      startLessonMsg = new bootstrap.Modal(document.getElementById('startLesson')) 
      if(store.state.currentLesson != null){
        store.commit('setLoading', false);
        checkStatus()
      }else{
        store.commit('setLoading', false);
      }
    })

    onBeforeUnmount(()=>{
      if(landingMsg)landingMsg.hide();
      if(startLessonMsg)startLessonMsg.hide();
    })

    onUnmounted(() => {
      if(!timerId){
        clearInterval(timerId);
      }
    });

    watch(result, value => {
      //landingMsg.show()
      store.commit('setData', value);
      localStorage.setItem('lessonId', value.thisDevice?.currentLesson?.id);
      localStorage.setItem('refreshIndicator', value.thisDevice?.refreshIndicator);
      if(value.thisDevice?.currentLesson !== null){
        checkStatus();
      }

      if(!timerId){
        timerId = setInterval(async () => {
          await checkData.refetch();
          const localLessonId = localStorage.getItem('lessonId');
          const localRefreshIndicator = localStorage.getItem('refreshIndicator');
          if((''+checkData.result.value.thisDevice?.currentLesson?.id) !== localLessonId){
            location.reload(true);
          }else if((''+checkData.result.value.thisDevice?.refreshIndicator) !== localRefreshIndicator){
            location.reload(true);
          }
        }, Number(value.config.frequencyPollCurrentLesson)*1000);
      }

    })

    watch(loading, value => {
      //console.log("loading:",value);
      store.commit('setLoading', value);
    })

    const { mutate:startLesson, onDone  } = useMutation(StartLessonMutation, 
      () => ({      
        variables:{
          startLessonId: String(store.state.currentLesson?.id)
        }
      })
    )

    const clickStarLesson = (()=>{
      startLessonMsg.show();
    })

    const confirmStarLesson = (()=>{
      if(startLessonMsg)startLessonMsg.hide();
      startLesson();
    })

    const closeStartLessonMsg = (()=>{
      if(startLessonMsg){
        startLessonMsg.hide();
      }
    })

    onDone((res) => {
      //console.log(res);
      if(res.data.startLesson.success){
        if(!timerId){
          //console.log('hello')
          clearInterval(timerId);
        }
        localStorage.setItem('lessonId', store.state.currentLesson?.id);
        store.commit('setLessonStartTime', res.data.startLesson.lesson.studentStartTime) 
        router.push({path:'/dashboard'})
      }else{
        store.commit('setLoading', false);
        msgData.value = res.data.startLesson.message
        landingMsg.show()
      }
    })

    function checkStatus(){
      let lessonId = localStorage.getItem('lessonId');
      if(store.state.currentLesson?.id === lessonId){
        if(store.state.currentLesson?.studentStartTime != null){
          let isHasActive = false
          if(!timerId){
            clearInterval(timerId);
          }
          for(let i in store.state.lessonUnits){
            //console.log(store.state.lessonUnits[i].type);
            if(store.state.lessonUnits[i].isActive){
              isHasActive = true
              router.push('/dashboard/'+store.state.lessonUnits[i].type+'/'+i)
              break;
            }
          }
          if(!isHasActive){
            if(!timerId){
              clearInterval(timerId);
            }
            router.push({path:'/dashboard'})
          }
        }
      }else{
        store.commit('setLoading', false);
      }
    }

    return{
      loading,
      clickStarLesson,
      student: computed(() => store.state.student?.name),
      msgData,
      closeStartLessonMsg,
      confirmStarLesson
    }
  }
};
</script>

<style scoped>

.msg-name{
  font-family: 'Montserrat';
  font-size: 32px;
  font-weight: 800;
  line-height: 44.8px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 20px;
}

</style>
  