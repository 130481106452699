<template>
  <div id="login">
    <div id="login-container">
      <img class="banner" src="@/assets/images/logo.svg" />
      <h2>Login to Your Account</h2>
      <p><input type="text" placeholder="Email" v-model="email" /></p>
      <p><input type="password" placeholder="Password" v-model="password" /></p>
      <p v-if="errMsg">{{ errMsg }}</p>
      <p><button @click="signIn">Login</button></p>
    </div>
  </div>
</template>

<script>
  import { ref } from 'vue'
  import { useRouter } from 'vue-router'
  import {
    getAuth,
    signInWithEmailAndPassword,
    onAuthStateChanged
  } from 'firebase/auth'

  export default {
    setup( ){
      const email = ref('') //test-device@beanadmin.com
      const password = ref('') //JscFtegU6MCq
      const errMsg = ref()
      const router = useRouter()
      const auth = getAuth();
      //console.log(auth);
      if(auth.currentUser){
        router.push('/')
      }
      const signIn = () => { 
        signInWithEmailAndPassword(auth, email.value, password.value) //
          .then((data) => {
            //console.log('Successfully logged in!');
            //console.log(auth.currentUser);
            router.push('/')
          })
          .catch(error => {
            console.log(error)
            //console.log(email.value)
            switch (error.code) {
              case 'auth/invalid-email':
                  errMsg.value = 'Invalid email'
                  break
              case 'auth/user-not-found':
                  errMsg.value = 'No account with that email was found'
                  break
              case 'auth/wrong-password':
                  errMsg.value = 'Incorrect password'
                  break
              default:
                  errMsg.value = 'Email or password was incorrect'
                  break
            }
          });
      }

      return {signIn, email, password, errMsg}
    }
  }
</script>

<style scoped>

#login{
  position: relative;
  display: flex;
  height: 100vh;
  width: 100vw;
  align-items: flex-start;
  justify-content: center;
  background-color: #FFF;
}

#login-container{
  position: relative;
  display: block;
  text-align: center;
  width: 800px;
  margin-top: 80px;
  background-color: #FFF;
}

.banner{
  position: relative;
  display: block;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  margin-bottom: 40px;
}


</style>
