<template>
  <div id="pageVideo" class="content-area" >
    <div class="page-container">
      <transition>
        <div class="page-content loaded" v-show="loaded">
          <div class="page-type">video</div>
          <div class="page-title">{{ videoData?.name }}</div>
          <div class="page-content-area">
            <div class="page-desc"><i class="bi bi-exclamation-circle desc-icon-exclamation"></i>{{ videoData?.instruction.replace("###name###", videoData.name) }}</div>
            <div id="vimeo-player-container" class="page-video-area"></div>
          </div>
          <div class="page-content-bottom">
            <!--<div class="page-button" @click="openQuitVideoModal"><i class="bi bi-house-door"></i>Back to home</div >-->
            <div class="page-button-right">
              <div class="page-button" @click="completeVideo">Watch Later</div>
              <div class="page-button black-style space-left" @click="finishVideo"><i class="bi bi-check-circle"></i>Finish Video</div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <QuitVideoModal @backHome="backToHomeModal" @closeModal="closeQuitVideoModal"/>
    <SaveVideoModal @saveTask="saveTaskModal" @closeModal="closeSaveVideoModal"/>
    <CompleteVideoModal @completeTask="completeTaskModal" @closeModal="closeCompleteVideoModal"/>
  </div>
</template>

<script>
import Vimeo from '@vimeo/player';

import { useStore } from 'vuex'; 
import { computed, watch, nextTick, onMounted, onUnmounted, ref, getCurrentInstance } from 'vue';
import { useRoute, useRouter } from 'vue-router'
import { useQuery, useMutation } from '@vue/apollo-composable'

import StartLessonUnitMutation from '../../components/gql/StartLessonUnit.gql'
import MarkLessonunitAsCompletedMutation from '../../components/gql/MarkLessonunitAsCompleted.gql'
import MarkVideoLessonunitAsCompletedMutation from '../../components/gql/MarkVideoLessonunitAsCompleted.gql'
import RecordLessonunitVideoPlayheadTimeMutation from '../../components/gql/RecordLessonunitVideoPlayheadTime.gql'

import QuitVideoModal from '@/components/modal/QuitVideo.vue';
import SaveVideoModal from '@/components/modal/SaveVideo.vue';
import CompleteVideoModal from '@/components/modal/CompleteVideo.vue';

import { eventBus } from '@/components/eventBus';

export default {
name: "Video",
components:{
  QuitVideoModal,
  SaveVideoModal,
  CompleteVideoModal
},
setup(props, { emit, expose }) {
  const route = useRoute()
  const router = useRouter()
  const store = useStore();

  const instance = getCurrentInstance();

  // Define the resetTime method
  const resetTime = () => {
      if (instance) {
          instance.emit('resetTime');
      }
  };
  
  let quitVideoModal;
  let saveVideoModal;
  let completeVideoModal;

  let loaded = ref(false);
  let activeBtn = ref(false);
  let player;
  let interval = ref();
  let videoCompletePercentage = ref(null);
  let onRecording = ref(false);

  const isPageStart = computed(() =>store.state.currentLesson.studentStartTime);
  const taskId = route.params.id;
  const videoData = store.state.lessonUnits[taskId];
  videoCompletePercentage.value = videoData?.videoCompletePercentage;
  const frequencySendVideoPlayheadTime = store.state.frequencySendVideoPlayheadTime;

  const { mutate:startLessonUnit, onDone:onStartLessonUnitDone  } = useMutation(StartLessonUnitMutation, 
    () => ({      
      variables:{
        startLessonunitId: String(videoData?.id)
      }
    })
  )

  onStartLessonUnitDone((res) => {
    if(res.data.startLessonunit.success){
    }
  })

  const { mutate: recordVideoPlayheadTime, onDone: onRecordVideoPlayheadTimeDone } = useMutation(RecordLessonunitVideoPlayheadTimeMutation)

  onRecordVideoPlayheadTimeDone((res) => {
    //console.log(res);
    console.log(res.data.recordLessonunitVideoPlayheadTime.lessonunit.videoCompletePercentage);
    if (res.data.recordLessonunitVideoPlayheadTime.success) {
      videoCompletePercentage.value = res.data.recordLessonunitVideoPlayheadTime.lessonunit.videoCompletePercentage;
      store.commit('updateData', {data:res.data.recordLessonunitVideoPlayheadTime.lessonunit, indexId:taskId});
    }
  });

  const { mutate: completeVideoMutate, onDone: onCompleteVideoDone } = useMutation(MarkLessonunitAsCompletedMutation, 
    () => ({      
      variables:{
        markLessonunitAsCompletedId: String(videoData?.id)
      }
    })
  )

  onCompleteVideoDone((res) => {
    //console.log(res);
    store.commit('setLoading', false);
    if(res.data.markLessonunitAsCompleted.success){
      store.commit('updateData', {data:res.data.markLessonunitAsCompleted.lessonunit, indexId:taskId});
      goToHome();
    }
  })

  const { mutate: finishVideoMutate, onDone: onFinishVideoDone } = useMutation(MarkVideoLessonunitAsCompletedMutation, 
    () => ({      
      variables:{
        markVideoLessonunitAsCompletedId: String(videoData?.id)
      }
    })
  )

  onFinishVideoDone((res) => {
    if(res.data.markVideoLessonunitAsCompleted.success){
      completeVideoMutate();
    }
  })


  const goToHome = (()=>{
    router.push({path:'/dashboard'})
  })

  const backToHomeModal = (()=>{
    resetTime();
    closeQuitVideoModal();
    goToHome();
  })

  const completeVideo = (()=>{
    resetTime();
    store.commit('setLoading', true);
    completeVideoMutate();
  })

  const finishVideo = (()=>{
    resetTime();
    store.commit('setLoading', true);
    finishVideoMutate();
  })

  const saveTaskModal = (()=>{
    closeSaveVideoModal();
    store.commit('setLoading', true);
    completeVideoMutate();
  })

  const completeTaskModal = (()=>{
    closeCompleteVideoModal();
    store.commit('setLoading', true);
    completeVideoMutate();
  })

  const stopVideo = (()=>{
    console.log('stop Video');
    if(player){
      player.pause();
      player.exitFullscreen();
    }
  })

  const openQuitVideoModal = (()=>{
      if(quitVideoModal){
        player.pause();
        quitVideoModal.show();
      }
  })

  const openSaveVideoModal = (()=>{
    if(activeBtn.value){
      if(saveVideoModal){
        player.pause();
        saveVideoModal.show();
      }
    }
  })

  const openCompleteVideoModal = (()=>{
    if(activeBtn.value){
      if(completeVideoModal){
        player.pause();
        completeVideoModal.show();
      }
    }
  })

  const closeQuitVideoModal = (()=>{
      if(quitVideoModal){
        quitVideoModal.hide();
      }
  })

  const closeSaveVideoModal = (()=>{
      if(saveVideoModal){
        saveVideoModal.hide();
      }
  })

  const closeCompleteVideoModal = (()=>{
      if(completeVideoModal){
        completeVideoModal.hide();
      }
  })

  onMounted(async()=>{
    await nextTick();
    
    resetTime();
    quitVideoModal = new bootstrap.Modal(document.getElementById('quitVideo')) 
    saveVideoModal = new bootstrap.Modal(document.getElementById('saveVideo')) 
    completeVideoModal = new bootstrap.Modal(document.getElementById('completeVideo')) 

    if(!videoData?.isActive && isPageStart.value !== null){
      startLessonUnit();
    }
      const options = {
        url: videoData.videoUrl,
        quality: '720p',
        fullscreen: true
      };
      store.commit('setLoading', true);
      player = new Vimeo('vimeo-player-container', options)
      player.ready().catch(errorHandler)
      player.on('loaded', updateLoading);
      player.on('play', onPlay);
      player.on('pause', onPause);
      player.on('fullscreenchange', onFullscreenChange);
      player.on('ratechange', onRateChange);
      player.on('seeked', onSeeked);
      player.on('durationchange', onDurationChange);

      function onPlay() {
        console.log('play');

        eventBus.triggerStopVideo = stopVideo;
        
        activeBtn.value = true;

        if (interval) {
          clearInterval(interval.value);
        }

        interval.value = setInterval(async () => {
          const currentTime = await player.getCurrentTime();
          if ((Math.floor(currentTime)%frequencySendVideoPlayheadTime)===0) {
            if(!onRecording.value){
              resetTime();
              //console.log('record la')
              onRecording.value = true;
              recordVideoPlayheadTime({
                recordLessonunitVideoPlayheadTimeId: String(videoData?.id),
                playheadTime: Math.floor(currentTime)
              });
            }
          }else{
            //console.log('no record la')
            onRecording.value = false;
          }
        }, 250);
      }

      function errorHandler(e){
        //console.log(e)
        store.commit('setLoading', false);
        loaded.value = true;
      }

      function onPause() {
        //console.log('pause');
        resetTime();
        if (interval) {
          clearInterval(interval.value);
        }
      }

      function onFullscreenChange() {
        //console.log('fullscreen');
      }

      function onRateChange() {
        //console.log('speed change');
      }

      function onSeeked(data) {
        resetTime();
        const currentTime = data.seconds;
        recordVideoPlayheadTime({
            recordLessonunitVideoPlayheadTimeId: String(videoData?.id),
            playheadTime: Math.floor(currentTime)
        });
        //console.log('Seeked:'+currentTime);
      }

      function onDurationChange(data) {
        const duration = data.duration; 
        //console.log('duration:'+duration);
      }

      function updateLoading(value) {
        //console.log('loaded video:',value)
        store.commit('setLoading', false);
        loaded.value = true;
        player.setQuality('720p')
        player.setCurrentTime(videoData?.videoPlayheadTime)
      }

    })

    onUnmounted(() => {
      if (player) {
        player = null;
      }

      if (interval) {
        clearInterval(interval.value);
      }
      
    });

  return {
    videoData,
    loaded,
    activeBtn,
    openQuitVideoModal,
    openSaveVideoModal,
    openCompleteVideoModal,
    closeQuitVideoModal,
    closeSaveVideoModal,
    closeCompleteVideoModal,
    backToHomeModal,
    saveTaskModal,
    completeTaskModal,
    completeVideo,
    finishVideo
  };
}
};
</script>

<style>

#pageVideo{
background: #F9F9F9;
}

#pageVideo .page-content{
  width: 750px;
}

#vimeo-player-container {
  width: 100%; /* Set the width to 100% */
  position: relative; /* Maintain aspect ratio */
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
}

#vimeo-player-container iframe{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

</style>
