<template>
    <div class="version-footer">
      Version: {{ version }}
    </div>
  </template>
  
  <script>
  import packageJson from '../../package.json';
  
  export default {
    data() {
      return {
        version: packageJson.version,
      };
    },
  };
  </script>
  
  <style scoped>
  .version-footer {
    position: fixed;
    bottom: 10px; 
    right: 10px; 
    background-color: rgba(255, 255, 255, 0.8); 
    padding: 5px 10px; 
    border-radius: 5px; 
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); 
    font-size: 12px; 
  }
  </style>