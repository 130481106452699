<template>
    <div :class="['taskItem', status,progressStatus]">
      <div class="taskItem-info">
        <div class="taskItem-info-num">{{ number+1 }}</div>
        <div class="taskItem-info-type">{{ type }}</div>
        <div class="taskItem-info-name">{{ name }}</div>
      </div>
      <div class="taskItem-type-circle">
        <img class="taskItem-type-img" :src="getImageUrl(type)" />
        <img class="taskItem-type-img-bg" src="@/assets/images/task-bg.png" />
      </div>
      <div v-if="status === 'completed' || status === 'continue'" class="taskItem-progress">
        <circular-progress-bar class="taskItem-progress" :progress="progress" size="280px" :ring-thickness="4" trackColor="#FFF" :progressColor="progressColor" />
      </div>
      <template v-if="!(type === 'test' && status === 'completed')">
        <RouterLink v-if="status !== 'notReady'" class="taskItem-btn" :class="progressStatus" :to="getLinkUrl(type, number)">
          {{ getButtonLabel(status) }}
        </RouterLink>
      </template>
      <div v-if="status === 'completed' || status === 'continue'" class="taskItem-status" :class="progressStatus">
        <div class="taskItem-completed-rate">{{ completedRate }}</div>
        <div class="taskItem-completed-text">Completed</div>
        <template v-if="type != 'video'">
          <div class="taskItem-time-total-spent">Total Time Spent: <div class="taskItem-time-spent-min">{{ Math.round(totalTimeSpent/60) }}min</div></div>
          <div v-if="status === 'completed'" class="taskItem-time-avg-spent">Avg Time Spent: <div class="taskItem-time-spent-min">{{ Math.round(avgTimeSpent/60) }}min</div></div>
        </template>
      </div>
    </div>
  </template>
  
<script>
import CircularProgressBar from '@/components/CircularProgressBar.vue';
import { useStore } from 'vuex';
import { computed } from 'vue';
  export default {
    components:{
        CircularProgressBar
    },
    data(){
      return{
        progress: 0,
        progressStatus: 'red-color',
        progressColor: '#E8D17E',
        completedRate: '0%',
        totalTimeSpent: '0 min',
        avgTimeSpent: '0 min'
      }
    },
    props: {
      number: {
        type: Number,
        required: true,
      },
      type: {
        type: String,
        required: true,
      },
      name: {
        type: String,
        required: true,
      },
      status: {
        type: String,
        required: true,
      }
    },
    beforeMount(){
      const store = useStore(); 
      const itemData = computed(() =>store.state.lessonUnits[this.number])
      //console.log(this.number);
      //console.log(itemData);
      if(this.type==='video'){
        /*
        if(itemData.value.markAsCompleted){
          this.progress = 100
        }else{
          this.progress = itemData.value.videoCompletePercentage
        }*/
        this.progress = itemData.value.videoCompletePercentage;
        this.completedRate = this.progress+"%"
      }else{
        this.progress = Math.floor((itemData.value.checkedQuestionIndexNos.length / itemData.value.instructQuestionIndexNos.length)*100)
        this.totalTimeSpent = itemData.value.totalTimeSpent
        this.avgTimeSpent = itemData.value.averageTimeSpent
        this.completedRate = itemData.value.instructQuestionIndexNos.length === 0 ? '-': this.progress+"%";        
      }
      if(this.progress < 40){
        this.progressColor = "#D4000077"
        this.progressStatus = 'red-color'
      }else{
        this.progressColor = "#E8D17E"
        this.progressStatus = 'yellow-color'
      }
    },
    methods: {
      getImageUrl(type) {
        if (type === 'video') {
        return new URL(`../../assets/images/task-video.png`, import.meta.url).href;
        } else if (type === 'exercise') {
        return new URL(`../../assets/images/task-exercise.png`, import.meta.url).href;
        } else if (type === 'test') {
        return new URL(`../../assets/images/task-test.png`, import.meta.url).href;
        }
      },
      getLinkUrl(type, number) {
        if (type === 'video') {
          return `/dashboard/video/${number}`;
        } else if (type === 'exercise') {
          return `/dashboard/exercise/${number}`;
        } else if (type === 'test') {
          return `/dashboard/test/${number}`;
        }
        return '#';
      },
      getButtonLabel(status) {
        if (status === 'completed') {
          if(this.type === 'video'){
            return 'Watch Again';
          }else{
            return 'Continue';
          }
        } else if (status === 'continue') {
          return 'Continue';
        } else if (status === 'start') {
          return 'Start';
        }
        // Default button label if status is not recognized
        return 'Button';
      },
    },
  };
  </script>
  
  <style scoped>

  </style>